import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const AppsImg = styled.div`
  position: relative;
  margin-left: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export const Wrap = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 52px;
  display: ${ props => props.isOpen ? 'block' : 'none' };
  min-width: 196px;
  width: max-content;
  border-radius: 8px;
  box-shadow: ${ props => props.theme.shadow[500] };
  background-color: ${ props => props.theme.palette.blank };
`

export const Content = styled.div`
  padding: 12px;
  border-top: 1px solid ${ props => props.theme.palette.grey20 };
`

export const LinkItem = styled.a`
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
` 

export const NavItem = styled(NavLink).attrs(() => ({ activeClassName: 'active-menu' }))`
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;

  &.active-menu {
    background: ${ props => props.theme.palette.primary }1A;
    color: ${ props => props.theme.palette.grey50 };
  }
  &.active-menu path:first-child {
    fill: ${ props => props.theme.palette.primary };
  }
`

export const Meet = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
`

export const Badge = styled.div`
  margin-left: auto;
  padding: 0 4px;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${ props => props.theme.palette.blank };
  border-radius: 8px;
  background-color: ${ props => props.theme.palette.primary };
`
