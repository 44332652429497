import NotifyIcon from '@/assets/images/notify-box.svg'
import { MouseEvent } from 'react'
import * as Styled from './index.style'

interface NotifyBadgeProps {
  haveMessage: boolean
  handleOpen: () => void
}


const NotifyBadge = ({
  haveMessage,
  handleOpen,
}: NotifyBadgeProps) => {
  const handleClick = (e: MouseEvent) => {
    handleOpen()
    e.stopPropagation()
  }

  return (
    <Styled.Badge $haveMsg={haveMessage}>
      <Styled.MessageBox
        src={NotifyIcon}
        $haveMsg={haveMessage ? 1 : 0}
        onClick={e => handleClick(e)}
        pointer
      />
    </Styled.Badge>
  )
}

export default NotifyBadge
