import styled, { keyframes } from 'styled-components'
import { SFSVG, SFText, SFButton } from '@otsofintech/sofinx-ui'
import Close from '@material-ui/icons/Close'

export const Badge = styled.div<{ havemsg: number }>`
  position: relative;
  width: 18px;
  height: 24px;
  &:before {
    ${ props => props.havemsg ? 'content: ""' : 'none' };;
    position: absolute;
    top: 0;
    right: -3px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 2px solid ${ props => props.theme.palette.blank };
    background-color: ${ props => props.theme.palette.primary };
  }
`

export const MessageBox = styled(SFSVG)<{ havemsg: number }>`
  fill: ${ props => props.theme.palette.grey200 };
  width: 18px;

  &:hover {
    fill: ${ props => props.theme.palette.grey40 };
  }
`

export const NotifyWrapper = styled.div`
  position: fixed;
  top: 88px;
  left: 100%;
  height: fit-content;

  // dialog = 1300
  z-index: 1500;
`

export const Container = styled.div`
  position: fixed;
  max-width: 384px;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 100%;
  z-index: 1302;
  backdrop-filter: blur(5px);
  transition: transform .5s cubic-bezier(0.15, 0.91, 0.47, 0.96);

  &.slide {
    transform: translateX(-100%);
  }
`

export const Drawer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #E6EBEB99;
  z-index: 20;
`

export const Header = styled.div`
  padding: 24px 24px 16px;
  display: flex;
  justify-content: space-between;
`

export const CloseButton = styled(SFSVG)`
  width: 40px;
  height: 40px;
  padding: 8px;
  font-size: 20px;
  color: ${ props => props.theme.palette.grey200 };
  background-color: ${ props => props.theme.palette.blank };
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    opacity: .7;
  }
`

export const ClearButton = styled(SFButton)`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  width: 112px;
  font-weight: 700;
  color: ${ props => props.theme.palette.grey200 };
  background-color: ${ props => props.theme.palette.blank };
  border: none;

  &:hover {
    color: ${ props => props.theme.palette.error2 } !important;
  }
`

export const Content = styled.div`
  padding: 16px 24px 32px;
  overflow-y: auto;
  height: calc(100vh - 88px);
  transition: all .2s;

  &.close {
    opacity: 0;
    transform: translateY(20px);
  }
`

export const TypeWrap = styled.div<{ show: boolean, length: number }>`
  position: relative;
  height: ${ props => props.length ? 118 + props.length * 10 + 'px' : '0 !important' };
  transition: all .5s cubic-bezier(0.15, 0.91, 0.47, 0.96);
  cursor: ${ props => !props.show ? 'pointer' : null };
`

export const Collapse = styled(SFButton)<{ show: number }>`
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 16px;
  width: 76px;
  display: flex;
  justify-content: space-between;
  height: 24px;
  background-color: ${ props => props.theme.palette.blank };
  border: none;
  box-shadow: ${ props => props.theme.shadow[100] };
  opacity: ${ props => props.show ? 1 : 0 };
  transition: opacity .2s;

  &.fade {
    opacity: 0;
  }
`

export const CollapseIcon = styled(SFSVG)`
  width: 12px;
  height: 14px;
  transform: rotate(180deg);
`

const boxShadow = keyframes`
  from {
    box-shadow: none;
  }
`

type NotifyCardProps = {
  index: number
  length: number
  isTypeOpen: boolean
  transform: any
}

export const NotifyCard = styled.div.attrs((props: any) => ({ style: { transform: props.transform } }))<NotifyCardProps>`
  padding: 16px;
  position: absolute;
  top: ${ props => props.isTypeOpen ? 0 : (props.length - props.index - 1) * 10 }px;
  left: 0;
  right: 0;
  border-radius: 8px;
  box-shadow: ${ props => props.theme.shadow[!props.isTypeOpen && props.index === 0 ? 700 : 100] };
  background-color: ${ props => props.theme.palette.blank };
  transition: transform .5s cubic-bezier(0.15, 0.91, 0.47, 0.96), opacity .2s;
  animation: ${boxShadow} .5s;
  animation-fill-mode: forwards;
  cursor: pointer;

  &.fade {
    opacity: 0;
  }
`

export const Title = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`

export const CloseIcon = styled(Close)`
  margin-left: auto;
  color: ${ props => props.theme.palette.grey30 };
  cursor: pointer;
`

export const Text = styled(SFText)`
  margin-bottom: 8px;
`


// new notify
export const NewWrapper = styled.div<{ height: number }>`
  position: relative;
  height: ${ props => props.height }px;
  transition: all .2s;
`

const slideIn = keyframes`
  to {
    right: 0;
  }
`

export const NewCard = styled.div`
  position: absolute;
  right: -110%;
  margin-bottom: 12px;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  box-shadow: ${ props => props.theme.shadow[100] };
  background-color: ${ props => props.theme.palette.blank };
  transition: all .2s cubic-bezier(0.15, 0.91, 0.47, 0.96);
  animation: ${slideIn} .5s cubic-bezier(0.15, 0.91, 0.47, 0.96) .2s;
  animation-fill-mode: forwards;

  &.fade {
    opacity: 0;
  }
`

export const BackTopIcon = styled(SFSVG)`
  margin-right: 4px;
  transform: rotate(-90deg);
  width: 16px;
`

export const BackTop = styled.div`
  position: fixed;
  margin: auto;
  padding: 4px 8px;
  display: flex;
  top: 40px;
  left: 0;
  right: 0;
  width: 115px;
  height: 32px;
  background-color: ${ props => props.theme.palette.tertiary };
  border-radius: 20px;
  opacity: 0;
  cursor: pointer;
  transition: all .5s;
  z-index: 20;

  &.appear {
    opacity: 1;
    top: 88px;
  }

  &.close {
    opacity: 0;
  }
`

export const Pre = styled.pre`
  margin: 0;
  line-height: 18px;
  white-space: break-spaces;
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`


export const NoMessageStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NoMessageImage = styled(SFSVG)`
  width: 84px;
  height: 112px;
  animation: ${fadeIn} 1s;
`
