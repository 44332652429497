import { toCamel, toUnderline, deepCopy } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './services'
import { FollowType } from '@/utils/enum'

// TODO: 暫時增加test switch供後端測試增量計算
// 搜尋關鍵字： testSwitch
const testSwitch = '?test_switch=true'

// 創建完整跟隨機器人
export const CompleteFollowRobotCreate = (query:Follow.Complete.Req): ApiService<Follow.Complete.Res> => {
  return request.post('/follow/complete', deepCopy(toUnderline(query)))
    .then(res => ({ isError: false, value: res.data.robot }))
    .catch(err => {
      apiErrorMsg('CompleteFollowRobotCreate', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 創建策略機器人
export const PostFollowStrategyService = (body: Follow.Strategy.Req): ApiService<Follow.Strategy.Res> => {
  const reqFormData = new FormData()

  reqFormData.append('name', body.name)
  reqFormData.append('investment', body.investment)
  reqFormData.append('real', String(body.real))
  reqFormData.append('delete_strategy_after_created', String(body.deleteStrategyAfterCreated))
  reqFormData.append('take_profit_enable', String(body.takeProfitEnable))
  reqFormData.append('take_profit', String(body.takeProfit))
  reqFormData.append('stop_loss_enable', String(body.stopLossEnable))
  reqFormData.append('stop_loss', String(body.stopLoss))
  reqFormData.append('stop_loss_mode', String(body.stopLossMode))
  reqFormData.append('startup_mode', String(body.startupMode))
  body.strategiesId.forEach(id => { reqFormData.append('strategies_id', id) })
  if(body.headshotFile) reqFormData.append('headshot_file', body.headshotFile)
  if(body.competitionId) reqFormData.append('competition_id', body.competitionId)

  return request.post('/follow/strategy', reqFormData)
    .then(res => ({ isError: false, value: toCamel(res.data.robot) }))
    .catch(err => {
      apiErrorMsg('PostFollowStrategyService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得機器人清單
export const GetFollowRobotList = (query?: Follow.RobotList.Req): ApiService<Follow.RobotList.Res<FollowType>> => {
  const params = toUnderline(deepCopy(query))
  // return request.get('/follow/robot',  { params })
  return request.get('/follow/robot' + testSwitch,  { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetFollowRobotList', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得機器人詳細
export const GetFollowRobotDetailService = (robotId: string, query?: Follow.RobotGet.Req): ApiService<Follow.RobotGet.Res<FollowType>> => {
  let paramArr = []

  if(query?.strategies?.some(item => item)) {
    const parameters = query.strategies.map((item => `strategies=${item}`))
    paramArr.push(`${parameters.join('&')}`)
  }

  if(query?.symbols?.some(item => item)) {
    const parameters = query.symbols.map((item => `symbols=${item}`))
    paramArr.push(`${parameters.join('&')}`)
  }

  if(query?.durationForProfit) paramArr.push(`duration_for_profit=${query.durationForProfit}`)

  // return request.get(`/follow/robot/${ robotId }?${ paramArr.join('&') }`)
  return request.get(`/follow/robot/${ robotId }${testSwitch}&${ paramArr.join('&') }`)
    .then(res => ({ isError: false, value: toCamel(res.data.robot) }))
    .catch(err => {
      apiErrorMsg('GetFollowRobotDetailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 調整機器人
export const PatchRobotService = (robotId: string, body: Follow.RobotUpdate.Req): ApiService<Follow.RobotDetail.Res<FollowType>> => {
  return request.patch(`/follow/robot/${ robotId }`, toUnderline(deepCopy(body)))
    .then(res => ({ isError: false, value: toCamel(res.data.robot) }))
    .catch(err => {
      apiErrorMsg('PatchRobotService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}


// 停止機器人
export const DelRobotService = (robotId: string): ApiService<boolean> => {
  return request.delete((`/follow/robot/${robotId}`))
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('DelRobotService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得機器人訂單
export const GetRobotOrdersListService = (query: Follow.GetOrdersList.Req): ApiService<Follow.GetOrdersList.Res> => {
  let parameterStr = `?limit=${query.limit || 0}&offset=${query.offset || 0}`
  return request.get(`/follow/robot/${query.robotId}/order${parameterStr}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetRobotOrdersListService',  err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得機器人持倉
export const GetRobotPositionListService = (id: string): ApiService<Follow.GetPositionList.Res> => {
  return request.get(`/follow/robot/${id}/position`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetRobotPositionList', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得機器人歷史交易
export const GetRobotClosedTradeListService = (id: string, query?: Follow.GetClosedTrades.Req): ApiService<Follow.GetClosedTrades.Res> => {
  const params = toUnderline(deepCopy(query))
  return request.get(`/follow/robot/${id}/closed_trade`, { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetRobotClosedTradeListService', err.response?.data?.error )
      return { isError: true, value: err.response?.data?.error  }
    })
}

// 取得機器人利潤分成
export const GetRobotProfitShareListService = (id: string, query?: Follow.GetProfitShare.Req): ApiService<Follow.GetProfitShare.Res> => {
  const params = toUnderline(deepCopy(query))
  return request.get(`/follow/robot/${id}/profit_share`, { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetRobotOrdersList', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}


// 取得機器人收益詳細
export const GetRobotProfitDetailService = (id: string, query?: Follow.GetProfitDetail.Req): ApiService<Follow.GetProfitDetail.Res> => {
  const symbolsParams = query?.symbols?.some(item => item !== '') ? `${query.symbols.map(item => 'symbols=' + item).join('&')}` : ''
  const strategiesParams = query?.strategies?.some(item => item !== '') ? `${query.strategies.map(item => 'strategies=' + item).join('&')}` : ''
  const params = [symbolsParams, strategiesParams].filter(param => !!param).join('&')
  return request.get(`/follow/robot/${id}/get_detailed_profit_data?${params}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetRobotProfitDetailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得機器人收益序列
export const GetRobotProfitDatasService = (id: string, query?: Follow.GetProfitDatas.Req): ApiService<Follow.GetProfitDatas.Res> => {

  const symbolsParams = query?.symbols?.some(item => item !== '') ? `${query.symbols.map(item => 'symbols=' + item).join('&')}` : ''
  const strategiesParams = query?.strategies?.some(item => item !== '') ? `${query.strategies.map(item => 'strategies=' + item).join('&')}` : ''
  const params = [symbolsParams, strategiesParams].filter(param => !!param).join('&')

  return request.get(`/follow/robot/${id}/get_profit_datas?${params}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetRobotProfitDatasService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
