import { ReactNode, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { UserAuth } from '@/utils/enum'
import styled from 'styled-components'
import HeaderBar from './header-bar'
import AsideMenu from './aside-menu'
import Footer from './footer'
import NotifySystem from './notify-system'

const Container = styled.div`
  width: 100%;
  background-color: ${ props => props.theme.palette.greyBG };

  @media screen and (min-width: ${props => props.theme.breakpoint.tablet}px) {
    width: 100%;
    min-width: fit-content;
  }
`

const Main = styled.main`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
`


interface LayoutProps {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const history =  useLocation()

  const mainNode = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if(mainNode.current) mainNode.current.scrollTo(0, 0)
  }, [history])

  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)
  const email = useSelector((state: RootState) => state.userInfo.email)

  const [haveMessage, setHaveMessage] = useState(false)
  const changeHaveMessage = (value: boolean) => setHaveMessage(value)

  // 抽屜狀態
  const [ isDrawerOpen, setIsDrawerOpen ] = useState(false)
  const handleOpen = () => setIsDrawerOpen(true)
  const handleClose = () => setIsDrawerOpen(false)

  return (
    history.pathname.includes('public-tools') ? <>{ children }</> :
      <Container className="container">

        <NotifySystem isOpen={isDrawerOpen} handleOpen={handleOpen} handleClose={handleClose} onHaveMessage={changeHaveMessage}/>

        {!isLogin &&
        <HeaderBar haveMessage={haveMessage} handleOpen={handleOpen} />
        }

      
        {isLogin && Boolean(email) && 
      <>
        <HeaderBar haveMessage={haveMessage} handleOpen={handleOpen} />
        <AsideMenu />
      </>
        }
     
        <Main ref={mainNode} className="main">
          { children }
        </Main>
        {isLogin && Boolean(email) &&
      <Footer isLogin={isLogin} />
        }

      </Container>
  )}

export default Layout
