import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store'
import { updateUserWallet, updateRobot, updateCreditDeposit } from '@/store/actions'
import useWebSocket from '@/hooks/useWebSocket'
import { useHistory } from 'react-router-dom'
import * as Styled from './style'
import { SFSVG, SFText, SFButton, SFAutoEllipsis, SFIconImage } from '@otsofintech/sofinx-ui'
import { toAmount } from '@otsofintech/sofinx-ui/lib/helper'
import realWalletIcon from '@/assets/images/freeze-color/real-wallet-icon.png'
import realWalletWhiteIcon from '@/assets/images/freeze-color/real-wallet-white.png'
import demoWalletIcon from '@/assets/images/freeze-color/demo-wallet-icon.png'
import demoWalletWhiteIcon from '@/assets/images/freeze-color/demo-wallet-white.png'
import arrowLeft from '@/assets/images/arrow-left.svg'
import visibility from '@/assets/images/visibility.svg'
import visibilityOff from '@/assets/images/visibility-off.svg'
import addIcon from '@/assets/images/add.svg'
import arrowLine from '@/assets/images/arrow-right.svg'
import { ClickAwayListener, Fade } from '@material-ui/core'
import { isOTSOProduction, isSofinqDomain } from '@/utils/helper'
import Big from 'big.js'
import { showMessage } from '@/hooks/useMessage'
import TutorialCreator from '../../../components/user-tutorial/tutorial-creator'
import DialogCheckKyc from '@/components/dialog-check-kyc'

enum Wallet {
  real = 'real',
  demo = 'demo',
}

type WalletMapType = {
  [key: string]: {
    label: string
    icon: string
    iconDisabled: string
  }
}

interface FooterProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AssetsBar = ({ isOpen, setIsOpen }: FooterProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const defaultWallet = isOTSOProduction() ? Wallet.demo : Wallet.real

  const [ isOpenWalletMenu, setIsOpenWalletMenu ] = useState(false)

  const { darkMode } = useSelector((state: RootState) => state.setting)
  const { robots } = useSelector((state: RootState) => state.robot)

  const { balance, demoBalance } = useSelector((state: RootState) => state.wallet)
  useWebSocket({
    channel: 'Event',
    channelString: 'event#Subscribe@Event',
    unsubscribeString: 'event#Unsubscribe@Event',
    needAuth: true,
    callback: (rawData: any) => {
      const balanceEventSet = new Set(['RobotCreate', 'RobotAdjust', 'RobotStop', 'DepositSuccess', 'DepositByBroker', 'DepositDemoBalanceSuccess', 'DepositDemoBalanceByBroker', 'WithdrawSuccess', 'WithdrawByBroker'])
      const robotEventSet = new Set(['RobotCreate', 'RobotAdjust', 'RobotStop'])
      const creditDepositSet = new Set(['BrokerTrustFundsStatusChanged'])
      const orderEventSet = new Set(['TradeFailed'])
      if (balanceEventSet.has(rawData?.payload?.type)) dispatch(updateUserWallet())
      if (robotEventSet.has(rawData?.payload?.type)) dispatch(updateRobot())
      if (creditDepositSet.has(rawData?.payload?.type)) dispatch(updateCreditDeposit())
      if (orderEventSet.has(rawData?.payload?.type)) {
        if(rawData?.payload?.data?.reason !== 'NOT_ENOUGH_FREE_MARGIN') showMessage({ icon: 'error', message: t('tradingFaild')})
      }
    },
  }, [])

  const [ showAmount, setShowAmount ] = useState(false)

  const walletMap: WalletMapType = {
    [Wallet.real]: { label: t('realFund'), icon: realWalletIcon, iconDisabled: realWalletWhiteIcon },
    [Wallet.demo]: { label: t('virtualFund'), icon: demoWalletIcon, iconDisabled: demoWalletWhiteIcon }
  }

  const [ activeWallet, setActiveWallet ] = useState(defaultWallet)
  const handleSelectWallet = (wallet: Wallet) => setActiveWallet(wallet)
  const handleOpenMenu = () => {
    setIsOpenWalletMenu(state => !state)
    setIsOpen(true)
  }

  const handleMouseLeave = () => {
    if(!isOpenWalletMenu) setIsOpen(false)
  }

  const [clickAwayEnabled, setClickAwayEnabled] = useState(true)
  const handleClickAway = () => {
    if(clickAwayEnabled) {
      setTimeout(() => {
        setIsOpenWalletMenu(false)
        setIsOpen(false)
      }, 200)
    }
  }

  // TODO: 獲利
  const profit = '0'

  /// 餘額
  const currentBalance = useMemo(() => {
    return activeWallet === Wallet.real ? balance : demoBalance
  }, [activeWallet, balance, demoBalance])

  /// 跟隨資金
  const followInvesment = useMemo(() => {
    return robots.filter(item => activeWallet === Wallet.real ? item.real : !item.real).reduce((acc, curr) => Number(new Big(acc).plus(new Big(curr.balance)).toFixed(2)), 0)
  }, [robots, activeWallet])

  /// 淨值
  const netValue = useMemo(() => Number(new Big(currentBalance).plus(profit).plus(followInvesment)), [currentBalance, profit, followInvesment])

  const tutorialAction = () => {
    setClickAwayEnabled(false)
    setShowAmount(true)
    setIsOpenWalletMenu(true)
    setIsOpen(true)
  }

  const tutorialClear = () => {
    setClickAwayEnabled(true)
    setShowAmount(false)
    setIsOpenWalletMenu(false)
    setIsOpen(false)
  }

  const userInfo = useSelector((state: RootState) => state.userInfo)
  const brokerSubscriptions = useSelector((state: RootState) => state.brokerSubscriptions)
  const brokerEnabledIdentityVerification = useMemo(() => {
    return brokerSubscriptions.find(item => item.feature === 'IDENTITY_VERIFICATION')?.enabled || false
  }, [brokerSubscriptions])
  const [isOpenCheckKycDialog, setIsOpenCheckKycDialog] = useState(false)

  const onClickDeposit = () => {
    if(!userInfo.kyc) {
      setIsOpenCheckKycDialog(true)
      return
    }
    history.push('/user-assets/deposit')
  }

  return TutorialCreator({
    actionFunc: tutorialAction,
    clearFunc: tutorialClear,
    step: 3,
    component:
    <>
      {/* 錢包選單 */}
      {isSofinqDomain() ||<Fade in={isOpenWalletMenu}>
        <Styled.WalletMenuContainer id="assets-bar-wallet-container" open={isOpenWalletMenu ? 1 : 0}>
          <Styled.WalletMenu>
            {
              Object.keys(walletMap).map(wallet => (
                <Styled.WalletMenuItem
                  active={activeWallet === wallet ? 1 : 0}
                  key={wallet}
                  onClick={ () => handleSelectWallet(wallet as Wallet) }
                  $darkMode={darkMode}
                >
                  <div style={{ marginRight: '16px' }}>
                    <SFIconImage
                      src={ activeWallet === wallet ? walletMap[wallet].icon : walletMap[wallet].iconDisabled }
                      width="24px"
                      height="24px"
                    />
                  </div>
                  <SFText level={2}>{walletMap[wallet].label}</SFText>
                </Styled.WalletMenuItem>
              ))
            }
          </Styled.WalletMenu>
        </Styled.WalletMenuContainer>
      </Fade>}

      <ClickAwayListener onClickAway={handleClickAway}>
        <Styled.Main
          hover={isOpen ? 1 : 0}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => handleMouseLeave()}
          hideForSofinq={isSofinqDomain()}
        >

          {/* 錢包 */}
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'contents', cursor: 'pointer' }} onClick={() => handleOpenMenu()}>
              <SFIconImage width="24px" height="24px" style={{ marginRight: '16px', marginLeft: '32px' }} src={walletMap[activeWallet].icon || '/favicon'} />
              <SFText level={2} color="white" flexGrow={1} flexShrink={1} width="120px" className="wallet-label-container" >
                <SFAutoEllipsis tooltipStyle={{ width: 'fit-content', maxWidth: 'calc(100% - 24px)'}} className="wallet-label" value={walletMap[activeWallet].label} tooltip />
                <SFSVG src={arrowLeft} className="arrow-icon" ml="4px" />
              </SFText>
            </div>
            <SFSVG src={ showAmount ? visibility : visibilityOff } className="visibility-icon" onClick={() => setShowAmount(state => !state)} />
          </div>

          {/* 小版面隱藏區塊 */}
          <div className="reactive">

            {/* 餘額 */}
            <Styled.AmountColumn>
              <SFText display="block" level={3} color="white" className="amount">
                { showAmount ?
                  <SFAutoEllipsis value={toAmount(currentBalance, 2, '')} tooltip /> :  '****'
                }
              </SFText>

              <SFText mt="8px" level={1} color="grey30" className="amount-title">
                <SFAutoEllipsis width="120px" value={`${t('assetBalance')} (USD)`} tooltip />
              </SFText>
            </Styled.AmountColumn>

            <SFSVG src={addIcon} color="grey30" ml="16px" mr="16px" style={{ flexShrink: 0 }} />

            {/* 跟隨資金 */}
            <Styled.AmountColumn>
              <SFText display="block" level={3} color="white" className="amount">
                { showAmount ?
                  <SFAutoEllipsis value={toAmount(followInvesment, 2, '')} tooltip /> :  '****'
                }
              </SFText>

              <SFText mt="8px" level={1} color="grey30" className="amount-title">
                <SFAutoEllipsis width="120px" value={`${t('robotBalance')} (USD)`} tooltip />
              </SFText>

            </Styled.AmountColumn>


            <SFSVG src={arrowLine} color="grey30" ml="24px" mr="24px" style={{ flexShrink: 0 }} />
          </div>

          {/* 淨值 */}
          <Styled.AmountColumn>
            <SFText display="block" level={3} color="white" className="amount">
              { showAmount ?
                <SFAutoEllipsis value={toAmount(netValue, 2, '')} tooltip /> :  '****'
              }
            </SFText>

            <SFText mt="8px" level={1} color="grey30" className="amount-title">
              <SFAutoEllipsis width="120px" value={`${t('totalBalance')} (USD)`} tooltip />
            </SFText>

          </Styled.AmountColumn>

          {/* 入金 (paper trade 無此功能) */}
          {
            !isOTSOProduction() && activeWallet === 'real' && <Styled.ActionContainer>
              <SFButton themecolor="primary" className="deposit-button" onClick={onClickDeposit}>
                <SFSVG src={arrowLine} color="white" mr="8px" width="16px" height="16px" style={{ transform: 'rotate(135deg)' }} />{t('deposit')}
              </SFButton>
            </Styled.ActionContainer>
          }

        </Styled.Main>
      </ClickAwayListener>
      <DialogCheckKyc isOpen={isOpenCheckKycDialog} setIsOpenCheckKycDialog={setIsOpenCheckKycDialog} brokerIdentityVerificationSubscription={brokerEnabledIdentityVerification}/>
    </>
  })
}

export default AssetsBar