import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Container = styled.aside`
  @media screen and (min-width: ${ props => props.theme.breakpoint.tablet}px ) and (max-width: ${ props => props.theme.breakpoint.desktop1 - 1 }px) { left: 16px };
  @media screen and (max-width: ${ props => props.theme.breakpoint.tablet - 1 }px) { display: none };
  position: fixed;
  top: 88px;
  left: 32px;
  z-index: 10;

  & > * {
    margin-bottom: 12px;
  }
`

export const NavWrapper = styled.div`
  width: 48px;
  overflow: visible;

  & .MuiCollapse-container {
    width: 196px;
  }
  & .MuiCollapse-wrapperInner {
    z-index: 1;
  }
`

export const ActionButton = styled.div<{ isOpen?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: ${ props => props.isOpen ? '8px 8px 0 0' : '8px' };
  background-color: ${ props => props.theme.palette.blank };
  cursor: pointer;
  transition: all .2s;
  overflow: hidden;

  &:hover {
    box-shadow: ${ props => props.isOpen ? 'none' : props.theme.shadow[500] };
  }

  &.hide {
    height: 0px;
    margin: 0px;
  }
`

export const MenuContent = styled.div`
  padding: 8px 12px;
`

export const MenuItem = styled(NavLink)`
  margin: 4px 0;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: all .2s;
  user-select: none;

  &:hover {
    color: ${ props => props.theme.palette.grey50 };
    background-color: ${ props => props.theme.palette.primary}1A;
  }

  &.active-menu {
    background: ${ props => props.theme.palette.primary }1A;
    color: ${ props => props.theme.palette.grey50 };
  }
  &.active-menu path:first-child {
    fill: ${ props => props.theme.palette.primary };
  }
  &.active-menu rect:first-child {
    fill: ${ props => props.theme.palette.primary };
  }
`

export const Badge = styled.div`
  margin-left: 16px;
  padding: 0 4px;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${ props => props.theme.palette.blank };
  border-radius: 8px;
  background-color: ${ props => props.theme.palette.primary };
`

export const Content = styled.div`
  width: max-content;
  min-width: 196px;
  border-radius: 0 8px 8px 8px;
  box-shadow: ${ props => props.theme.shadow[600] };
  background-color: ${ props => props.theme.palette.blank };
`
