import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetCompanyCustomizedMenuService } from '@/services/api-helper/config'

const initState = [] as Config.CompanyCustomizedMenu.Res

// extraReducers
const updateCompanyCustomizedMenu = createAsyncThunk('config/customized-menu', async () => {
  const result = await GetCompanyCustomizedMenuService()
  return result.isError === false ? result.value : initState
})

const companyCustomizedMenuSlice = createSlice({
  name: 'companyCustomizedMenu',
  initialState: initState,
  reducers: {
    clearCompanyCustomizedMenu: () => initState
  },

  extraReducers: (builder) => {
    builder.addCase(updateCompanyCustomizedMenu.fulfilled, (state, action) => action.payload || initState)
  },
})

export const companyCustomizedMenuActions = { ...companyCustomizedMenuSlice.actions, updateCompanyCustomizedMenu}
export default companyCustomizedMenuSlice.reducer
