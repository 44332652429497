import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { UserAuth } from '@/utils/enum'
import { webSocket } from '@/services/api-helper/services/webSocket'

interface userWebSocketProps {
  channel: string // 頻道名稱
  channelString: string // 訂閱發送字串
  unsubscribeString : string // 退訂發送字串
  callback: Function // 收到通知時執行callback
  needAuth?: boolean // 此通知是否需要認證
}

const useWebSocket = ({
  channel,
  channelString,
  unsubscribeString,
  callback,
  needAuth = false,
}: userWebSocketProps, deps: any[]) => {
  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)
  const wsState = useSelector((state: RootState) => state.wsState)

  useEffect(() => {
    if (needAuth && isLogin === false) return
    const symbol = Symbol(channelString)
    webSocket.onSubscribe(channel, channelString, callback, needAuth, symbol)

    return () => {
      webSocket.send(unsubscribeString)
      webSocket.delCallback(channel, symbol)
    }
  }, [...deps, isLogin, wsState])
}

export default useWebSocket
