import { SFButton, SFDialog, SFSVG, SFText } from '@otsofintech/sofinx-ui'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@/assets/images/freeze-color/info-icon-primary.svg'
import WarnIcon from '@/assets/images/freeze-color/competition-terminated.svg'
import { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'


const DialogCheckKyc = ({isOpen, setIsOpenCheckKycDialog, brokerIdentityVerificationSubscription}:{isOpen:boolean, setIsOpenCheckKycDialog: Dispatch<SetStateAction<boolean>>, brokerIdentityVerificationSubscription: boolean}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const styleMap = {
    hasKyc: {
      icon: InfoIcon,
      text:t('finishIdentityVerificationBeforeProceeding'),
      cancelBtnText: t('cancel')
    },
    noKyc: {
      icon: WarnIcon,
      text:t('contactBrokerForFutureIdentityVerification'),
      cancelBtnText: t('back')
    }
  }
  return (
    <SFDialog open={isOpen} onClose={() => setIsOpenCheckKycDialog(false)} width='500px'>
      <div style={{display: 'flex', marginBottom: '24px'}}>
        <SFSVG width='64px' height='64px' mr='24px' src={brokerIdentityVerificationSubscription?styleMap.hasKyc.icon:styleMap.noKyc.icon} />
        <div style={{display:'flex', flexDirection: 'column'}}>
          <SFText level={3} color='grey50' fontWeight='600' mb='16px'>{t('identityUnverified')}</SFText>
          <SFText level={2} color='grey40'>{brokerIdentityVerificationSubscription ? styleMap.hasKyc.text : styleMap.noKyc.text}</SFText>
        </div>
      </div>
      <div style={{display:'flex',justifyContent:'flex-end'}}>
        <SFButton themecolor='grey' circle onClick={() => setIsOpenCheckKycDialog(false)}>{brokerIdentityVerificationSubscription ? styleMap.hasKyc.cancelBtnText : styleMap.noKyc.cancelBtnText}</SFButton>
        {brokerIdentityVerificationSubscription && <SFButton themecolor='primary' ml='14px' circle onClick={() => {
          history.push('/setting')
          setIsOpenCheckKycDialog(false)
        }}>{t('goVerify')}</SFButton>}
      </div>
    </SFDialog>
  )
}

export default DialogCheckKyc