import dayjs from 'dayjs'
import Big from 'big.js'
import { ellipsisZero } from '@otsofintech/sofinx-ui/lib/helper'

type TimeForm<T extends string> = Partial<Record<T, string>>

// 收到UTC格式會自動轉時區
export const timeFormat = (timeForm: TimeForm<'value' | 'to'> = {}) => {
  const { value, to = 'YYYY/MM/DD HH:mm:ss' } = timeForm

  if(!value || !dayjs(value).isValid()) return ''
  return dayjs(value).format(to)
}

export const toUTCFormat = (timeForm: TimeForm<'value' | 'to' | 'from'> = {}) => {
  const { value, to = 'YYYY/MM/DD HH:mm:ss', from } = timeForm

  if(!value || !dayjs(value, from).isValid()) return ''
  return dayjs(value, from).utc().format(to)
}

export const toLocalFormat = (timeForm: TimeForm<'value' | 'to' | 'from'> = {}) => {
  const { value, to = 'YYYY/MM/DD HH:mm:ss', from } = timeForm

  if(!value || !dayjs(value, from).isValid()) return ''
  return dayjs.utc(value, from).local().format(to)
}


export const toTimeStamp = (value: string) => dayjs(value).valueOf() || 0
/**
 * 報酬率
 * @param {string|undefined} profit 總獲利額
 * @param {string|undefined} principal 本金
 */
export const GetProfitRate2 = (profit: string | number, principal: string | number, percent = false) => {
  if((!+profit && +profit !== 0)|| !+principal) return '0.00' + (percent ? '%' : '')
  return parseFloat(((+profit / +principal) * 100).toFixed(2)).toFixed(2) + (percent ? '%' : '')
}
/**
 * 秒數轉換年月(約略估算，一個月以30天計)
 * @param {any} s 秒數
 * @returns ${year}y${month}m
 */
export const Second2DurationYM = (s: string | number) => {
  let year = 0
  const y = 60 * 60 * 24 * 365
  let month = 0
  const m = 60 * 60 * 24 * 30
  let temp
  if(!s) return ''

  year = Math.floor(+s / y)
  /// 扣掉年份剩餘時間
  temp = +s % y
  month = Math.floor(+temp / m)

  const yearStr = +year ? `${year}Y` : ''
  const monthStr = +month ? `${month}M` : ''

  return `${yearStr}${monthStr}`
}

/**
 * api 字串轉百分比
 * @param {any} value 數值
 * @returns ${100*value}
 */
export const String2Rate2 = (value: string | number) => {
  if(!+value && value !== '0') return ''
  else return `${(100 * +value).toFixed(2)}`
}
/**
 * api 字串轉百分比，含符號
 * @param {any} value 數值
 * @returns ${100*value}%
 */
export const String2Rate2Symbol = (value: string | number) => {
  try {
    if(!+value && value !== '0' && value !== 0) return ''
    else return `${(100 * +value).toFixed(2)}%`
  } catch {
    console.error(`String2Rate2Symbol parse error: ${value}`)
    return ''
  }
}

/**
 * api 字串轉金額
 * @param {any} value 數值
 */
export const String2Amount = (value: string | number) => {
  try {
    if(!+value && +value !== 0) return ''
    if(+value < 0) {
      value = (-value).toString()
      let parts = value.split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return `-$${parts.join('.')}`
    } else {
      value = value.toString()
      let parts = value.split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return `$${parts.join('.')}`
    }
  } catch {
    console.error(`String2Amount parse error: ${value}`)
    return ''
  }
}
/**
 * TODO 整合公式
 * api 字串轉金額小數第二位
 * @param {any} value 數值
 */
export const String2Amount2 = (value: string | number) => {
  try {
    if(!+value && +value !== 0) return ''
    if(+value < 0) {
      value = (-value).toString()
      let parts = value.split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      if(parts.length > 1) {
        parts[1] = parts[1].slice(0,2)
      }
      return `-$ ${parts.join('.')}`
    } else {
      value = value.toString()
      let parts = value.split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      if(parts.length > 1) {
        parts[1] = parts[1].slice(0,2)
      }
      return `$ ${parts.join('.')}`
    }
  } catch {
    console.error(`String2Amount2 parse error: ${value}`)
    return ''
  }
}
/**
 * 金額轉字串
 * @param {*} str
 * @returns
 */
export const Amount2String = (str: string) => {
  try {
    if(!str) return '0'
    let valueStr = str.replace('$', '')
    let value
    valueStr = valueStr.split(',').join('')
    if(valueStr.indexOf('.') > -1) {
      value = `${+valueStr.split('.')[0]}.${valueStr.split('.')[1]}`
    } else {
      value = `${+valueStr.split('.')[0]}`
    }
    // eslint-disable-next-line no-self-compare
    if(+value !== +value) return '0'
    return `${value}`
  } catch {
    console.error(`Amount2String parse error: ${str}`)
    return ''
  }
}
/**
 * 秒數轉換日時分
 * @param {any} s 秒數
 * @returns ${day}日${hour}時${minute}分
 */
export const Second2DurationDHM = (s: string | number) => {
  if(!s) return

  let day = '0'
  let d = 60 * 60 * 24
  let hour = '0'
  let h = 60 * 60
  let minute = '0'
  let m = 60
  let temp

  day = (+s / d).toFixed()
  /// 扣掉天數剩餘時間
  temp = +s % d
  hour = (+temp / h).toFixed()
  /// 扣掉時數剩餘時間
  temp = temp % h
  minute = (temp / m).toFixed()

  const dayStr = +day ? `${day.padStart(2, '0')}D` : ''
  const hourStr = +hour ? `${hour.padStart(2, '0')}H` : ''
  const minuteStr = +minute ? `${minute.padStart(2, '0')}m` : ''
  return `${dayStr}${hourStr}${minuteStr}`
}

/**
 * 將每日累積獲利轉為每年每月的獲利金額
 * @param {Common.ProfitData[]} datas
 * @returns
 */
export const transformMonthProfit = (datas: any) => {
  let newDatas = {} as any
  for(let i in datas){
    let data = datas[i]
    let timeSplitStr = data.time.split('-')
    let y = timeSplitStr[0]
    let m = timeSplitStr[1]
    if(!newDatas[y]) newDatas[y] = {}
    if(!newDatas[y][m]) newDatas[y][m] = []
    newDatas[y][m].push(data)
  }
  let pre = 0
  for(let y in newDatas){
    for(let m in newDatas[y]){
      let end = parseFloat(newDatas[y][m][newDatas[y][m].length - 1].profit)
      let profit = end - pre
      pre = end
      newDatas[y][m] = profit
    }
  }
  return newDatas
}
/**
 * 資料序列轉換成年月績效
 */
export const transformMonthGrandTotalProfit = (datas: any) => {
  let newDatas = {} as any
  for(let i in datas){
    let data = datas[i]
    let timeSplitStr = data.time.split('-')
    let y = parseInt(timeSplitStr[0])
    let m = parseInt(timeSplitStr[1])
    if(!newDatas[y]) newDatas[y] = {}
    if(!newDatas[y][m]) newDatas[y][m] = []
    newDatas[y][m].push(data)
  }
  let pre = 0
  for(let y in newDatas){
    for(let m in newDatas[y]){
      let end = parseFloat(newDatas[y][m][newDatas[y][m].length - 1].profit)
      let profit = end - pre
      pre = end
      newDatas[y][m] = profit
    }
  }
  return newDatas
}

export const StringToFixed = (str: string, fixed: number) => {
  if(!+str) return ''
  return (+str).toFixed(fixed)
}

/**
 * 字串/數字轉金額
 * @param value
 * @param sign 金額符號
 * @returns
 */
export const stringToAmount = (value: any, sign: string = '', decimal: number = 0) => {
  value = String(value)
  if(!+value && +value !== 0) return ''
  if(+value < 0) {
    value = (-value).toString()
    let parts = value.split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if(parts.length > 1) {
      parts[1] = parts[1].slice(0,2)
    }
    if(decimal && parts.length === 1) {
      parts.push(''.padEnd(decimal, '0'))
    }
    return `-${sign}${parts.join('.')}`
  } else {
    value = value.toString()
    let parts = value.split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if(parts.length > 1) {
      parts[1] = parts[1].slice(0,2)
    }
    if(decimal && parts.length === 1) {
      parts.push(''.padEnd(decimal, '0'))
    }
    return `${sign}${parts.join('.')}`
  }
}

/**
 * 金額轉字串
 * @param {*} str
 * @returns
 */
export const amountToString = (str: string) => {
  if(!str) return '0'
  let valueStr = str.replace('$', '')
  let value
  valueStr = valueStr.split(',').join('')
  if(valueStr.indexOf('.') > -1) {
    value = `${+valueStr.split('.')[0]}.${valueStr.split('.')[1]}`
  } else {
    value = `${+valueStr.split('.')[0]}`
  }
  if(Number.isNaN(+value)) return str
  return `${value}`
}

/**
 * 計算報酬率
 * @param profit 利潤
 * @param invesment 投入資金
 * @param decimal 小數位數
 * @returns
 */
export const getProfitRate = (profit: any, invesment: any, decimal: number = 2) => {
  if(Number.isNaN(+profit) || Number.isNaN(+invesment) || profit === '') return ''
  try {
    const p = new Big(profit)
    const i = new Big(invesment)
    return p.div(i).times(100).toFixed(decimal)
  } catch(e) {
    console.error(e)
    return ''
  }
}

/**
 * 計算時間區間
 * @param start
 * @param end default=now
 */
export const getDuration = (start: string, end?: string) => {
  const startTime = dayjs(start)
  const endTime = end? dayjs(end) : dayjs(Date.now())
  const timeDiff = endTime.diff(startTime)
  const duration = dayjs.duration(timeDiff)


  return ({
    days: duration.days(),
    hours: duration.hours()
  })
}

/**
 * 計算時間區間
 * @param sec
 * @returns
 */
export const secToDuration = (sec: number) => {
  return ({
    days: Math.floor(sec / (60 * 60 * 24)),
    hours: Math.floor(sec % (60 * 60 * 24) / (60 * 60) ),
    min: Math.floor(sec % (60 * 60 * 24) % (60 * 60) / 60 ),
    sec: Math.floor((sec % (60 * 60 * 24) % (60 * 60) % 60)),
  })
}


/// input限制數字輸入
export const inputToNumberString = ({
  value,
  decimal = 2,
}: {
  value: string
  decimal?: number
}) => {
  /// 過濾非數字
  let val = value.replace(/[^\d.]/g, '')

  /// 無小數點
  if(!decimal) return val

  /// 過濾多餘小數點
  let arr = val.split('.').slice(0, 2)

  if(arr[0]) arr[0] = Number(arr[0]).toString()
  if(arr[1]) arr[1] = arr[1].slice(0, decimal)
  console.log({ value, arr })
  return val = arr.join('.')
}

/// 收益率顯示格式
export const formatProfitrate = ({
  value,
  noDataSign = '-',
  percentSign = ''
}: {
  value: string | Number
  noDataSign?: string
  percentSign?: string
}) => {
  if(isNaN(Number(value)) || value === '') return `${noDataSign}`
  else return `${ellipsisZero((Number(value || 0) * 100).toFixed(2))}${percentSign}`
}
