import { lazy, Suspense, useCallback, useMemo } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { AliveScope } from 'react-activation'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import Layout from '@/pages/layout'
import AuthRoute from './AuthRoute'
import Loading from './loading'
import { UserAuth } from '@/utils/enum'
import { isOTSOProduction, isSofinqDomain } from '@/utils/helper'

const NotFound = lazy(() => import(/* webpackChunkName: "not-found" */ '@otsofintech/sofinx-ui/lib/components/SFErrorNotFound'))
const Login = lazy(() => import(/* webpackChunkName: "login" */ '@/pages/login'))
const Register = lazy(() => import(/* webpackChunkName: "register" */ '@/pages/register'))
const Home = lazy(() => import(/* webpackChunkName: "home" */ '@/pages/home'))
const Signal = lazy(() => import(/* webpackChunkName: "signal" */ '@/pages/signal'))
const SignalDetail = lazy(() => import(/* webpackChunkName: "signal-detail" */ '@/pages/signal-detail'))
const RobotList = lazy(() => import(/* webpackChunkName: "robot-list" */ '@/pages/robot-list'))
const RobotDetail = lazy(() => import(/* webpackChunkName: "robot-detail" */ '@/pages/robot-detail'))

const Strategy = lazy(() => import(/* webpackChunkName: "strategy" */ '@/pages/strategy'))
const UserAssets = lazy(() => import(/* webpackChunkName: "user-assets" */ '@/pages/user-assets'))
const Referral = lazy(() => import(/* webpackChunkName: "referral" */ '@/pages/referral'))
const CompetitionExploreList = lazy(() => import(/* webpackChunkName: "competition-explore-list" */ '@/pages/competition-explore-list'))
const CompetitionExploreDetail = lazy(() => import(/* webpackChunkName: "competition-explore-detail" */ '@/pages/competition-explore-detail'))
const CompetitionList = lazy(() => import(/* webpackChunkName: "competition-list" */ '@/pages/competition-list'))
const CompetitionDetail = lazy(() => import(/* webpackChunkName: "competition-detail" */ '@/pages/competition-detail'))
const Setting = lazy(() => import(/* webpackChunkName: "setting" */ '@/pages/setting'))
const SettingAddLogin = lazy(() => import(/* webpackChunkName: "setting-add-login" */ '@/pages/setting-add-login'))
const SettingLogin = lazy(() => import(/* webpackChunkName: "setting-login" */ '@/pages/setting-login'))
const SettingGoogle = lazy(() => import(/* webpackChunkName: "setting-google" */ '@/pages/setting-google'))
const SettingIdentityVerification = lazy(() => import(/* webpackChunkName: "setting-identity-verification" */ '@/pages/setting-identity-verification'))
const BankAccount = lazy(() => import(/* webpackChunkName: "setting-bank-account" */ '@/pages/setting-bank-account'))
const Withdraw = lazy(() => import(/* webpackChunkName: "withdraw" */ '@/pages/withdraw'))
const WithdrawWireTransfer = lazy(() => import(/* webpackChunkName: "withdraw-wire-transfer" */ '@/pages/withdraw-wire-transfer'))
const WithdrawCrypto = lazy(() => import(/* webpackChunkName: "withdraw-crypto" */ '@/pages/withdraw-crypto'))
const Deposit = lazy(() => import(/* webpackChunkName: "deposit" */ '@/pages/deposit'))
const DepositWireTransfer = lazy(() => import(/* webpackChunkName: "deposit-wire-transfer" */ '@/pages/deposit-wire-transfer'))
const DepositPaymentChannel = lazy(() => import(/* webpackChunkName: "deposit-payment-channel" */ '@/pages/deposit-payment-channel'))
const DepositCrypto = lazy(() => import(/* webpackChunkName: "deposit-crypto" */ '@/pages/deposit-crypto'))
const DepositHDWallet = lazy(() => import(/* webpackChunkName: "deposit-hd-wallet" */ '@/pages/deposit-hd-wallet'))
const Favorite = lazy(()=> import(/* webpackChunkName: "favorite" */ '@/pages/favorite'))
const WithoutEmail = lazy(()=> import(/* webpackChunkName: "without-email" */ '@/pages/without-email'))
const AppDownload = lazy(()=> import(/* webpackChunkName: "app-download" */ '@/pages/app-download'))
const EarningChart = lazy(()=> import(/* webpackChunkName: "earning-chart" */ '@/public-tools/earning-chart'))
const MonthPnlChart = lazy(()=> import(/* webpackChunkName: "month-pnl-chart" */ '@/public-tools/month-pnl-chart'))


const authMap = {
  guest: [ UserAuth.GUEST ],
  member: [ UserAuth.MEMBER ],
  public: [ UserAuth.GUEST, UserAuth.MEMBER ],
}

const Router = () => {
  // 權限
  const auth = useSelector((state: RootState) => state.auth)
  const checkAuth = useCallback((authList: UserAuth[]) => {
    return authList.includes(auth)
  }, [auth])

  const userInfo = useSelector((state: RootState) => state.userInfo)
  const isEmailOk = useMemo(() =>  !userInfo.id || userInfo.email , [userInfo])

  return (
    <BrowserRouter>
      <AliveScope>
        <Loading />
        <Layout>
          <Suspense fallback={null}>
            <Switch>
              <AuthRoute exact path="/login" component={Login} auth={checkAuth(authMap.guest)} to="/" />
              <AuthRoute exact path="/register" component={Register} auth={checkAuth(authMap.guest)} to="/" />

              <AuthRoute exact path="/without-email" component={WithoutEmail} auth={checkAuth(authMap.member) && !isEmailOk } to="/"/>              
              <AuthRoute exact path="/" component={Home} auth={checkAuth(authMap.public)} />
              <AuthRoute exact path="/signal" component={Signal} auth={checkAuth(authMap.public)}/>
              <AuthRoute exact path="/signal/:id" component={SignalDetail} auth={checkAuth(authMap.public)}/>
              <AuthRoute exact path="/robot" component={RobotList} auth={checkAuth(authMap.member)}/>
              <AuthRoute exact path="/robot/:id" component={RobotDetail} auth={checkAuth(authMap.member)}/>
              <AuthRoute exact path="/strategy" component={Strategy} auth={checkAuth(authMap.member)}/>
              {!isSofinqDomain() && <AuthRoute exact path="/referral" component={Referral} auth={checkAuth(authMap.member)}/>}
              {!isSofinqDomain() && <AuthRoute exact path="/user-assets" component={UserAssets} auth={checkAuth(authMap.member)}/>}
              {!isSofinqDomain() && <AuthRoute exact path="/competition-explore" component={CompetitionExploreList} auth={checkAuth(authMap.public)}/>}
              {!isSofinqDomain() && <AuthRoute exact path="/competition-explore/:id" component={CompetitionExploreDetail} auth={checkAuth(authMap.public)} />}
              <AuthRoute exact path="/competition" component={CompetitionList} auth={checkAuth(authMap.member)}/>
              <AuthRoute exact path="/competition/:id" component={CompetitionDetail} auth={checkAuth(authMap.member)}/>
              <AuthRoute exact path="/setting" component={Setting} auth={checkAuth(authMap.member)}/>
              <AuthRoute exact path="/setting/add-login" component={SettingAddLogin} auth={checkAuth(authMap.member)}/>
              <AuthRoute exact path="/setting/login" component={SettingLogin} auth={checkAuth(authMap.member)}/>
              <AuthRoute exact path="/setting/google" component={SettingGoogle} auth={checkAuth(authMap.member)}/>
              <AuthRoute exact path="/setting/bank-account" component={BankAccount} auth={checkAuth(authMap.member)}/>
              <AuthRoute exact path="/setting/identity-verification" component={SettingIdentityVerification} auth={checkAuth(authMap.member)}/>
              <AuthRoute exact path="/favorite" component={Favorite} auth={checkAuth(authMap.member)}/>
              <AuthRoute exact path="/app-download" component={AppDownload} auth={checkAuth(authMap.public)} /> 
              <AuthRoute exact path="/app-download/ios" component={AppDownload} auth={checkAuth(authMap.public)} /> 
              <AuthRoute exact path="/app-download/android" component={AppDownload} auth={checkAuth(authMap.public)} /> 

              {/* 對外工具 */}
              <AuthRoute exact path="/public-tools/earning-chart" component={EarningChart} auth={checkAuth(authMap.public)} /> 
              <AuthRoute exact path="/public-tools/month-pnl-chart" component={MonthPnlChart} auth={checkAuth(authMap.public)} /> 

              {/* 真實資金出入金 (paper trade 無此功能) */}
              { !isOTSOProduction()&&!isSofinqDomain() && 
              <>
                <AuthRoute exact path="/user-assets/withdraw" component={Withdraw} auth={checkAuth(authMap.member)} /> 
                <AuthRoute exact path="/user-assets/withdraw/wire-transfer" component={WithdrawWireTransfer} auth={checkAuth(authMap.member)} />
                <AuthRoute exact path="/user-assets/withdraw/crypto" component={WithdrawCrypto} auth={checkAuth(authMap.member)} />
                <AuthRoute exact path="/user-assets/deposit" component={Deposit} auth={checkAuth(authMap.member)} />
                <AuthRoute exact path="/user-assets/deposit/wire-transfer" component={DepositWireTransfer} auth={checkAuth(authMap.member)} /> 
                <AuthRoute exact path="/user-assets/deposit/payment-channel" component={DepositPaymentChannel} auth={checkAuth(authMap.member)} /> 
                <AuthRoute exact path="/user-assets/deposit/crypto" component={DepositCrypto} auth={checkAuth(authMap.member)} /> 
                <AuthRoute exact path="/user-assets/deposit/hd-wallet" component={DepositHDWallet} auth={checkAuth(authMap.member)} /> 
                
              </>
              }

              <AuthRoute path="*" component={NotFound} auth={checkAuth(authMap.public)} />
            </Switch>
          </Suspense>
        </Layout>
      </AliveScope>
    </BrowserRouter>
  )
}

export default Router
