import styled, { keyframes } from 'styled-components'
import Close from '@material-ui/icons/Close'

const slide = keyframes`
  10% { transform: translateX(calc(-100% - 24px)) }
  90% { transform: translateX(calc(-100% - 24px)) }
`

export const MessageBar = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: ${ props => props.theme.palette.blank };
  box-shadow: ${ props => props.theme.shadow[700] };
  opacity: 0;
  transform: translateX(0);
  transition: all .2s cubic-bezier(0.15, 0.91, 0.47, 0.96);

  &.slide-in {
    animation: ${slide} 5s cubic-bezier(0.15, 0.91, 0.47, 0.96);
  }

  &.appear {
    opacity: 1;
  }
`

export const Content = styled.div`
  margin: 0 14px;
  width: 216px;
`

export const CloseIcon = styled(Close)`
  color: ${ props => props.theme.palette.grey30 };
  cursor: pointer;
`
