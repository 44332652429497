import { useEffect } from 'react'
import { getMessageDetail } from './typeMap'

// 通知測試程式
const testMode = false

export const useNotifyTest = (queue: any) => {

  useEffect(() => {
    if (testMode) {
      setInterval(() => {
        const detail = getMessageDetail({
          id: String(Math.random()),
          type: 'RobotStop',
          time: '',
          data: {
            id: String(Math.random()),
            name: 'Chris機器人',
            reason: 'TAKE_PROFIT',
          },
        })
        queue.push(detail)
      }, 2000)
    }
  }, [])
}
