import { toCamel } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './services'

// 取得broker託管資金
export const GetCreditDepositStatusService = (): ApiService<Broker.GetCreditDepositStatus.Res> => {
  return request.get('/broker/trust_funds/status')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCreditDepositStatusService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

//取得broker設置的利潤分成比例
export const GetMerchantProfitShareService = (): ApiService<Broker.GetMerchantProfitShare.Res> => {
  return request.get('/broker/merchant_profit_share_percent').then(res => ({ isError: false, value: toCamel(res.data)})).catch(err => {
    apiErrorMsg('GetMerchantProfitShareService', err.response?.data?.error)
    return { isError: true, value: err.response?.data?.error}
  })
}
//查看該user所屬broker(返回id、名稱、active code)
export const GetBrokerInfoService = (): ApiService<Broker.GetBrokerInfo.Res> => {
  return request.get('/broker').then(res => ({ isError: false, value: toCamel(res.data)})).catch(err => {
    apiErrorMsg('GetBrokerInfoService', err.response?.data?.error)
    return { isError: true, value: err.response?.data?.error}
  })
}