import { createGlobalStyle } from 'styled-components'
import { consoleProject, onErrorEvent } from '@otsofintech/sofinx-ui/lib/helper'
import { initLanguage } from '@/assets/locales/i18n'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import objectSupport from 'dayjs/plugin/objectSupport'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

export const middleware = () => {
  dayjs.extend(utc)
  dayjs.extend(objectSupport)
  dayjs.extend(duration)
  dayjs.extend(customParseFormat)

  initSentry()
  onErrorEvent()
  consoleProject()
  initLanguage()
}

function initSentry () {
  const isProd = /^.*\.sofinx.com$/.test(window.location.host)
  if (isProd) {
    Sentry.init({
      dsn: 'https://a3d6ebbbb25141cf9d00ae73b795f766@o1361986.ingest.sentry.io/6704801',
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    })
  }
}

export const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${ props => props.theme.palette.greyBG };
    transition: all .2s;
  }

  body {
    margin: 0;
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    ::-webkit-scrollbar {
      display: none;
    }
    cursor: default;
    & .ui-datepicker {
    margin-right: 200px !important;
  }
  }

  * {
    /* 避免mui或其他外部組件覆蓋字體 */
    font-family: Inter, -apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;

    box-sizing: border-box;
    text-decoration: none;

    &:disabled {
      cursor: not-allowed;
    }
  }

  ::selection {
    background: ${ props => props.theme.palette.extend1 };
  }
`
