import { RootState } from '@/store'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

interface TutorialCreatorProps {
  step: number
  component?: JSX.Element
  actionFunc?: () => void
  clearFunc?: () => void
  width?: string
  height?: string
}

const SteplRoot = styled.div<{ width?: string, height?: string }>`
  ${ props => props.width && `width: ${ props.width }` };
  ${ props => props.height && `height: ${ props.height }` };
  position: relative;
`

const ComponentWrapper = styled.div<{ active: boolean }>`
  position: relative;
  ${ props => props.active && 'pointer-events: none' };
  ${ props => props.active && 'z-index: 101' };
`

const TutorialCreator = ({
  step,
  component = <></>,
  actionFunc = () => {},
  clearFunc = () => {},
  width,
  height,
  ...props
}: TutorialCreatorProps) => {


  const currentStep = useSelector((state: RootState) => state.tutorialStep)


  useEffect(() => {
    if(currentStep === step) actionFunc()

    return () => clearFunc()
  }, [currentStep, step])

  
  return (
    <>
      {
        <SteplRoot id={`user-tutorial-step-${step}`} className={`user-tutorial-step-${step}-root`}>
          <ComponentWrapper active={currentStep === step}>
            {component}
          </ComponentWrapper>
        </SteplRoot> 
      }
   
    </>
  )  
}

export default TutorialCreator