import { GetCreditDepositStatusService } from '@/services/api-helper/broker'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


const initialState: Broker.GetCreditDepositStatus.Res = {
  brokerId: '',
  status: 'NORMAL',
}

const updateCreditDeposit = createAsyncThunk('credit-deposit', async () => {
  const result = await GetCreditDepositStatusService()
  return result.isError === false ? result.value || initialState : initialState
})

const creditDepositSlice = createSlice({
  name: 'credit-deposit',
  initialState,
  reducers: {
    clearCreditDeposit: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(updateCreditDeposit.fulfilled, (state, action) => action.payload)
  }
})

export const creditDepositActions = { ...creditDepositSlice.actions, updateCreditDeposit }
export default creditDepositSlice.reducer
