import { Route, Redirect, useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'


interface AuthRouteProps {
  path: string
  component: React.LazyExoticComponent<any>
  exact?: true
  auth?: boolean
  to?: string
  hasEmail?: boolean
}
interface LocationType {
    redirectPath: string
  
}
const AuthRoute = ({path, component: Component, exact, auth = true, to = '/login', hasEmail = true}: AuthRouteProps) => {
  const attrs = {
    exact: exact === true,
  }
  const userInfo = useSelector((state: RootState) => state.userInfo)
  /// 尚未取得user資料(未完成登入程序) or 已取得email
  const isEmailOk = useMemo(() =>  !userInfo.id || userInfo.email , [userInfo])
  const location = useLocation<LocationType>()
  return <Route {...attrs} path={path} render={() => auth ? isEmailOk || path === '/without-email' ? <Component /> : <Redirect to='/without-email' /> : <Redirect to={(location).state?.hasOwnProperty('redirectPath')?location.state?.redirectPath:to} /> } />
}


export default AuthRoute
