import { useEffect, useRef, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { SFText, SFAutoEllipsis } from '@otsofintech/sofinx-ui'
import { NotifyWrapper, NotifyBar, Title, Content, CloseIcon } from './style'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

interface NotifyProps {
  isDrawerOpen: boolean
  list: any[]
  handleOpen: () => void
}

const Notify = ({
  isDrawerOpen,
  list,
  handleOpen,
}: NotifyProps) => {
  const { t } = useTranslation()

  const brokerName = useSelector((state: RootState) => state.companyInfo.name)

  const [isOpen, setIsOpen] = useState(false)
  const notifyNode = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (notifyNode.current === null) return

    if (isOpen) {
      notifyNode.current.classList.add('slide')
      notifyNode.current.classList.add('expend')
    }
    else {
      notifyNode.current.classList.remove('slide')
      notifyNode.current.classList.remove('expend')
    }
  }, [isOpen])


  const [notifyList, setNotifyList] = useState<any[]>([])

  useEffect(() => {
    if (notifyList.length === 0 && isOpen) setIsOpen(false)
    else if (notifyList.length && !isOpen) setIsOpen(true)
  }, [notifyList])


  useEffect(() => {
    if (isDrawerOpen === false) setNotifyList(list.slice(-3))
  }, [list, isDrawerOpen])

  const handleClose = (event?: MouseEvent) => {
    if (event) event.stopPropagation()
    if (notifyNode.current === null) return

    notifyNode.current.classList.remove('slide')
    setTimeout(() => setNotifyList([]), 500)
  }

  const handleClick = () => {
    handleClose()
    handleOpen()
  }

  const getClass = (index: number) => index + 1 !== notifyList.length ? 'covered' : ''

  return (
    <NotifyWrapper ref={notifyNode}>
      { notifyList.map((item, index) => (
        <NotifyBar
          key={item.id}
          className={ getClass(index) }
          style={{ zIndex: index + 1 }}
          reverseIndex={ notifyList.length - (index + 1) }
          onClick={handleClick}
        >
          <Title>
            { item.icon ? <item.icon onClick={handleClose} /> : null}
            <SFText ml="12px" level={2} color="grey50" fontWeight="700">{ t(item.title, { ...item.data, brokerName: brokerName }) }</SFText>
            <CloseIcon onClick={handleClose} />
          </Title>
          <Content>
            <SFText width={1} level={1} color="grey40">
              <SFAutoEllipsis value={ t(item.message, { ...item.data, brokerName: brokerName }) } />
            </SFText>
          </Content>
        </NotifyBar>
      ))}
    </NotifyWrapper>
  )
}

export default Notify
