import { createSlice } from '@reduxjs/toolkit'

type Setting = typeof initialState

// local-storage
function getFromLocalSetting (): Setting {
  try { return JSON.parse(localStorage?.getItem('setting') as string) || initialState }
  catch { return initialState }
}

function setToLocalStorage (data: Setting) {
  localStorage?.setItem('setting', JSON.stringify(data))
}

function initSetLocal () {
  const settingData = getFromLocalSetting()
  setToLocalStorage(settingData)
}

const initialState = {
  browserNotify: false,
  darkMode: false,
  chartColor: 'greenUp' as 'greenUp' | 'redUp',
}

// 初始化設置
initSetLocal()

const settingSlice = createSlice({
  name: 'setting',
  initialState: getFromLocalSetting(),
  reducers: {
    setBrowserNotify: (state, action: { payload: boolean }) => {
      const settingData = { ...state, browserNotify: action.payload }
      setToLocalStorage(settingData)
      return settingData
    },
    setDarkMode: (state, action: { payload: boolean }) => {
      const settingData = { ...state, darkMode: action.payload }
      setToLocalStorage(settingData)
      return settingData
    },
    setChartColor: (state, action: { payload: 'greenUp' | 'redUp' }) => {
      const settingData = { ...state, chartColor: action.payload }
      setToLocalStorage(settingData)
      return settingData
    },
  }
})

export const settingActions = { ...settingSlice.actions }
export default settingSlice.reducer
