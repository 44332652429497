import { useEffect, useRef, useState, Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import { PutNotifyService } from '@/services/api-helper/notify'
import { SFText } from '@otsofintech/sofinx-ui'
import { NewWrapper, NewCard, Text, Title, CloseIcon } from './index.style'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

interface NewNotifyProps {
  list: any[]
  setList: Dispatch<React.SetStateAction<any[]>>
}

const NewNotify = ({
  list = [],
  setList,
}: NewNotifyProps) => {
  const { t } = useTranslation()

  const brokerName = useSelector((state: RootState) => state.companyInfo.name)

  const wrapperNode = useRef<HTMLDivElement>(null)

  const handleClose = (id: string) => {
    wrapperNode.current?.querySelector(`[id="${id}"]`)?.classList.add('fade')

    PutNotifyService({ id: [ id ] })

    setTimeout(() => {
      setList(prev => {
        const newValue =  [...prev]
        const targetIndex = list.findIndex(item => item.id === id)
        newValue.splice(targetIndex, 1)
        return newValue
      })
    }, 200)
  }

  // calc height
  const [height, setHeight] = useState(0)
  useEffect(() => {
    let totalHeight = 0
    const length = wrapperNode.current?.childElementCount || 0

    for (let i = length - 1; i >= 0; --i) {
      const item = wrapperNode.current?.childNodes[i] as HTMLDivElement
      item.style.setProperty('transform', `translateY(${totalHeight}px)`)
      totalHeight += item.offsetHeight + 12
    }

    setHeight(totalHeight)
  }, [list])

  return (
    <NewWrapper ref={wrapperNode} height={height}>
      { list.map(item => (
        <NewCard id={item.id} key={item.id}>
          <Title>
            { item.icon ? <item.icon /> : null }
            <SFText ml="12px" level={2} color="grey50" fontWeight="700">
              { t(item.title, { ...item.data, brokerName }) }
            </SFText>
            <CloseIcon onClick={() => handleClose(item.id)} />
          </Title>
          <Text width={1} level={1} color="grey40">
            { t(item.message, { ...item.data, brokerName }) }
          </Text>
        </NewCard>
      ))}
    </NewWrapper>
  )
}

export default NewNotify
