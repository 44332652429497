import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

function getDevice (breakpoint: any): Common.ViewerDevice {
  const width = window.innerWidth

  if (breakpoint.desktop1 <= width) return 'pc'
  else if (breakpoint.tablet + 1 <= width) return 'tablet'
  else return 'mobile'
}

function useResize () {
  const breakpoint = useSelector((state: RootState) => state.theme.breakpoint)
  const [device, setDevice] = useState(getDevice(breakpoint))

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)

    function handleResize () {
      const newDevice = getDevice(breakpoint)
      setDevice(newDevice)
    }
  }, [])

  return { device }
}

export default useResize
