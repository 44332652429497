import { ReactNode, useState } from 'react'
import { Palette } from '@otsofintech/sofinx-ui/lib/style'
import RobotIcon from '@/assets/images/robot.svg'
import CheckIcon from '@/assets/images/check-done.svg'
import CopyIcon from '@/assets/images/copy.svg'
import ErrorIcon from '@/assets/images/error-fill.svg'
import StopIcon from '@/assets/images/stop.svg'
import ErrorOutlineIcon from '@/assets/images/error-outline.svg'
import CheckOutlineIcon from '@/assets/images/check-outline.svg'
import TrashIcon from '@/assets/images/trash.svg'

export type IconTypes = keyof typeof iconMap

type IconMap = {
  [name: string]: { icon: string, color: Palette | string }
}

export const iconMap: IconMap = {
  robot: { icon: RobotIcon, color: 'primary' },
  robotError: { icon: RobotIcon, color: 'error2' },
  check: { icon: CheckIcon, color: 'primary' },
  copy: { icon: CopyIcon, color: 'primary' },
  error: { icon: ErrorIcon, color: 'error2' },
  stop: { icon: StopIcon, color: 'error2' },
  warning: { icon: ErrorOutlineIcon, color: 'warn2' },
  warning2: { icon: ErrorOutlineIcon, color: 'error2' },
  checkOutline: { icon: CheckOutlineIcon, color: 'warn2' },
  trash: { icon: TrashIcon, color: 'error2' },
}

export type Message = {
  icon: ReactNode | IconTypes
  message: ReactNode
  symbol?: Symbol
}

export let showMessage = (message: Message): void => {}

const useMessage = () => {
  const [message, setMessage] = useState<Message>({
    icon: 'check',
    message: '',
    symbol: Symbol(Date.now()),
  })

  showMessage = (message) => setMessage({ ...message, symbol: Symbol(Date.now()) })

  return {
    messager: message,
    setMessage
  }
}

export default useMessage

