import { EntryMode, FollowType } from './enum'
import { getDefaultLanguage } from '@/assets/locales/i18n'

function ObjectCombine<T extends Object> (rawValue: T, value?: Partial<T>): T {
  type Keys = keyof typeof value

  if (value === undefined) return rawValue

  if (Object.prototype.toString.call(rawValue) !== '[object Object]') return value as T
  else {
    (Object.keys(rawValue) as Keys[]).forEach(key => {
      if (rawValue.hasOwnProperty(key)) rawValue[key] = ObjectCombine(rawValue[key], value[key])
    })
  }

  return rawValue
}


export const UserInfo = (value?: Common.User): Common.User => {
  const result = {
    id: '',
    headshot: '',
    username: '',
    email: '',
    countryCode: '',
    phone: '',
    kyc: false,
    kycLastModifiedTime: '',
    language: getDefaultLanguage(),
  }

  return ObjectCombine(result, value)
}

export const UserWallet = (value?: Common.Wallet):Common.Wallet => {
  const result = {
    balance: '0',
    demoBalance: '0',
    tokenBalance: '0',
    robotsBalance: '0',
    demoRobotsBalance: '0',
  }

  if (value) {
    if (value.balance) result.balance = value.balance
    if (value.demoBalance) result.demoBalance = value.demoBalance
    if (value.tokenBalance) result.tokenBalance = value.tokenBalance
    if (value.robotsBalance) result.robotsBalance = value.robotsBalance
    if (value.demoRobotsBalance) result.demoRobotsBalance = value.demoRobotsBalance
  }

  return result
}

export const CommonSignal = (value?: Common.Signal):Common.Signal => {
  const result = {
    id: '',
    name: '',
    describe: '',
    headshot: '',
    enable: false,
    platform: '',
    enabledAt: '',
    recentTime: '',
    netValue: '',
    provider: {
      id: '',
      name: '' ,
      headshot: '',
    },
    computed: {
      numOfFollowers: 0,
      investmentOfFollow: '',
    },
    followSetting: {
      investmentMin: '',
      investmentMax: '',
      profitSharePercent: '',
    },
    performance: {
      principal: '',
      averageProfit: '',
      averageLoss: '',
      profit: '',
      drawdown: '',
      drawdownRatio: '',
      duration: '',
      tradePerWeek: '',
      tradesTotal: 0,
      avgHoldingDuration: '',
      apr7: '',
      apr30: '',
      apr180: '',
      sharpeRatio: '',
      volMax: '',
      volMin: '',
      volAvg: '',
      winRate: '',
      profitDatas: [] as Common.ProfitData[],
      updatedAt: '',
      rateOfReturn: '',
      balance:'',
    },
    symbols: [] as Common.SignalSymbol[],
    createdAt: '',
    activeLevel: 0 as Common.Signal['activeLevel'],
    connStatus: 'CONNECTING' as Common.Signal['connStatus']
  }

  return ObjectCombine(result, value)
}

export const CommonStrategySymbol = (value?: Common.StrategySymbol): Common.StrategySymbol  => {
  const result: Common.StrategySymbol = {
    id: '',
    signalId: '',
    signalName: '',
    symbolId: '',
    symbolName: '',
    symbolIcon: '',
    reverse: false,
    multiple: '1',
    timeStart: '',
    timeEnd: '',
    volStart: '',
    volEnd: '',
  }

  if (value) {
    if (value.id) result.id = value.id
    if (value.signalId) result.signalId = value.signalId
    if (value.signalName) result.signalName = value.signalName
    if (value.symbolId) result.symbolId = value.symbolId
    if (value.symbolName) result.symbolName = value.symbolName
    if (value.symbolIcon) result.symbolIcon = value.symbolIcon
    if (value.reverse) result.reverse = value.reverse
    if (value.multiple) result.multiple = value.multiple
    if (value.timeStart) result.timeStart = value.timeStart
    if (value.timeEnd) result.timeEnd = value.timeEnd
    if (value.volStart) result.volStart = value.volStart
    if (value.volEnd) result.volEnd = value.volEnd
  }

  return result
}

export const CommonRobot = (value?: Common.Robot<FollowType>): Required<Common.Robot<FollowType>> => {
  const result: Required<Common.Robot<FollowType>> = {
    id: '',
    name: '',
    headshot: '',
    investment: '0',
    balance: '',
    followType: FollowType.COMPLETE,
    entryMode: EntryMode.DONT_COVER_POSITION,
    real: true,
    createdAt: '',
    updatedAt: '',
    stoppedAt: '',
    stopped: true,
    computed: {
      proportionOfFunds: '',
      profitUnsettlement: '',
      margin: ''
    },
    performance: {
      profit: '0',
      apr7: '0',
      apr30: '0',
      apr180: '0',
      profitDatas: [],
      drawdown: '',
      drawdownRatio: '',
      avgHoldingDuration: '',
      updatedAt: '',
    },
    performanceByStrategy: {
      profit: '0',
      apr7: '0',
      apr30: '0',
      apr180: '0',
      profitDatas: [],
      drawdown: '',
      drawdownRatio: '',
      avgHoldingDuration: '',
      updatedAt: '',
    },
    symbols: [],
    strategies: [],
    signal: CommonRobotSignal(),
    competition: { id: '' },
    takeProfit: '',
    takeProfitEnable: false,
    stopLoss: '',
    stopLossEnable: false,
    stopLossMode: 'FIXED',
    merchantProfitSharePercent: '0',
    startupMode: 'ZERO_POSITION',
    totalProfit: '0',
    unrealizedProfit: '0',
    realizedProfit: '0',
    equity: '0',
    robotsMarginBalance: '0',
  }

  return ObjectCombine(result, value)
}

export const CommonRobotSignal = (value?: Common.RobotSignal): Required<Common.RobotSignal> => {
  const result:Required<Common.RobotSignal> = {
    id: '',
    name: '',
    headshot: '',
    enable: false,
    multiple: '',
    investmentMin: '0',
    investmentMax: '0',
    drawdown: '',
    drawdownRatio: '',
    profitSharePercent: '',
    principal: '',
    profit: '',
    duration: '',
  }

  return ObjectCombine(result, value)
}

export const CommonCompetitionBase = (value?: Partial<Common.CompetitionBase> ,type?: Common.CompetitionType): Common.CompetitionBase => {
  const result: Common.CompetitionBase = {
    id: '',
    title: '',
    icon: '',
    timeStart: '',
    timeEnd: '',
    numberOfReward: 0,
    highestReward: '',
    type: 'TRADE',
    status: 'COMING',
    description: '',
    terminateReason: '',
    computed: {
      numberOfJoined: 0,
    },
  }
  if(type === 'PROMOTE') {
    result.promote = {
      conditionNumberOfPromote: 0,
      conditionDepositAmount: '',
    }
  }
  if(type === 'TRADE') {
    result.trade = {
      isReal: true,
      investAmount: '',
      stopRobot: false,
      isSpecifySymbol: false,
      minVolume: '',
      symbols: [],
      maxLossThresholdEnable: false,
      maxLossThresholdPercentage: '0',
      maxMultiplierEnable: false,
      maxMultiplier: '1',
      enforceStopLoss: false,
      enforceStopLossPercentage: '0',
      requirePreviousParticipation: false,
      requirePreviousCompetition: '0',
    }
  }

  const obj = ObjectCombine(result, value)
  if(value?.trade) obj.trade = value.trade
  if(value?.promote) obj.promote = value.promote

  return obj
}

export const CommonDepositOrder = (value?: Partial<Common.DepositOrder>): Common.DepositOrder => {
  const result: Common.DepositOrder = {
    id: '',
    userId: '',
    type: '',
    time: '',
    amount: '',
    status: '',
    exchangeRate: '',
    fee: '',
    adminSettingFee: '',
    feeType: '',
    currency: '',
    paidAmount: '',
    userDepositInfo: '',
    userComment: '',
    brokerComment: '',
    note:'',
    user: {
      id: '',
      username: '',
      email: '',
      countryCode: '',
      phone: '',
    },
    transferInfoForWire: {
      bankName: '',
      branchName: '',
      bankAddress: '',
      bankPhone: '',
      bankCodeType: '' as any,
      bankCode: '',
      payeeAccount: '',
      payeeName: '',
      payeePhone: '',
      payeeAddress: '',
    },
    transferInfoForCrypto: {
      mainnetName: '',
      walletAddress: '',
      currencyAliasName: '',
      imageUrl: ''
    },
    transferInfoForHdwallet: {
      network: '',
      walletAddress: '',
    },
    transferInfoForPaymentChannel: {
      name: '',
      needBrokerVerify: true,
      thirdPartyPaymentAmount: '',
      thirdPartyPaymentCurrency: '',
      thirdPartyPaymentMsg: '',
      thirdPartyPaymentOrderId: '',
      thirdPartyPaymentPageUrl: '',
    },
  }

  const obj = ObjectCombine(result, value)
  return obj
}

export const CommonWithdrawOrder = (value?: Partial<Common.WithdrawOrder>): Common.WithdrawOrder => {
  const result: Common.WithdrawOrder = {
    id: '',
    userId: '',
    type: '',
    time: '',
    amount: '',
    status: '',
    exchangeRate: '',
    fee: '',
    feeType: '',
    adminSettingFee: '',
    currency: '',
    paidAmount: '',
    userComment: '',
    brokerComment: '',
    user: {
      id: '',
      username: '',
      email: '',
      countryCode: '',
      phone: '',
    },
    transferInfoForWire: {
      name: '',
      address: '',
      username: '',
      accountNumber: '',
      bankCodeType: '' as any,
      bankCode: '',
    },
    transferInfoForCrypto: {
      mainnetName: '',
      walletAddress: '',
      currencyAliasName: '',
      imageUrl: ''
    },
  }

  const obj = ObjectCombine(result, value)
  return obj
}


export const CompetitionWallet = (value?: Common.CompetitionWallet): Common.CompetitionWallet => {
  const result = {
    competitionId: '',
    competitionTitle: '',
    isReal: false,
    balance: '0',
    isParticipantDisqualified: false,
  }

  if (value) {
    if (value.balance) result.balance = value.balance
    if (value.competitionId) result.competitionId = value.competitionId
    if (value.competitionTitle) result.competitionTitle = value.competitionTitle
    if (value.isReal) result.isReal = value.isReal
    if(value.isParticipantDisqualified) result.isParticipantDisqualified = value.isParticipantDisqualified
  }

  return result
}


export const TradingActivityPayload = (data: Common.RobotTradingActivityPayload): Common.RobotTradingActivityPayload => {
  const result: Common.RobotTradingActivityPayload = {
    robotId: '',
    type: 'ORDER_COMPLETED',
    position: {
      id: '',
      signalId: '',
      signalName: '',
      symbolId: '',
      side: 0,
      volume: '',
      openPrice: '',
      openTime: '',
      closePrice: '',
      closeTime: '',
      swap: '',
      margin: '',
      symbolName: '',
    },
    order: {
      id: '',
      positionId: '',
      signalId: '',
      signalOrderId: '',
      signalTicket: '',
      strategyId: '',
      symbolId: '',
      side: 0,
      volume: '',
      closedVolume: '',
      price: '',
      createdTime: '',
      completedTime: '',
      fee: '',
      profit: '',
      comment: '',
      reason: '',
      status: 'NEW',
      entry: 'NONE',
      symbolName: ''
    }
  }


  return ObjectCombine(result, data)
}

export const CompanyInfo = (value?: Common.CompanyInfo): Common.CompanyInfo => {
  const result = {
    name: '',
    email: '',
    countryCode: '',
    phone: '',
  }

  return ObjectCombine(result, value)
}