import { Palette, theme } from '@otsofintech/sofinx-ui/lib/style'
import { HTMLAttributes } from 'react'

interface ChartColorIconProps extends HTMLAttributes<HTMLOrSVGElement> {
  up: Palette
  down: Palette
  active?: boolean
}

const ChartColorIcon = ({
  up,
  down,
  active = false,
  ...props
}: ChartColorIconProps) => {
  return (
    <svg width="96" height="48" viewBox="0 0 96 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.75" y="0.75" width="94.5" height="46.2045" rx="2.55159" fill="#FAFAFA"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M42.3327 16.5898C41.421 16.5898 40.6819 17.3289 40.6819 18.2406V27.1441C40.6819 28.0558 41.421 28.7949 42.3327 28.7949H44.8491C45.7609 28.7949 46.4999 28.0558 46.4999 27.1441V18.2406C46.4999 17.3289 45.7609 16.5898 44.8491 16.5898H42.3327Z" fill={theme.palette[up]}/>
      <path d="M42.8639 13.7272C42.8639 13.3256 43.1895 13 43.5911 13C43.9928 13 44.3184 13.3256 44.3184 13.7272V16.5804C44.3184 16.9821 43.9928 17.3077 43.5911 17.3077C43.1895 17.3077 42.8639 16.9821 42.8639 16.5804V13.7272Z" fill={theme.palette[up]}/>
      <path d="M42.8641 28.8042C42.8641 28.4025 43.1897 28.0769 43.5913 28.0769C43.993 28.0769 44.3186 28.4025 44.3186 28.8042V31.6573C44.3186 32.059 43.993 32.3846 43.5913 32.3846C43.1897 32.3846 42.8641 32.059 42.8641 31.6573V28.8042Z" fill={theme.palette[up]}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M51.1509 23.5897C50.2391 23.5897 49.5001 24.3288 49.5001 25.2405V36.9389C49.5001 37.8506 50.2391 38.5897 51.1509 38.5897H53.6673C54.579 38.5897 55.3181 37.8506 55.3181 36.9389V25.2405C55.3181 24.3288 54.579 23.5897 53.6673 23.5897H51.1509Z" fill={theme.palette[down]}/>
      <path d="M51.682 20.7272C51.682 20.3256 52.0076 20 52.4093 20C52.8109 20 53.1365 20.3256 53.1365 20.7272V23.5804C53.1365 23.9821 52.8109 24.3077 52.4093 24.3077C52.0076 24.3077 51.682 23.9821 51.682 23.5804V20.7272Z" fill={theme.palette[down]}/>
      <path d="M51.6822 38.1241C51.6822 37.7225 52.0078 37.3969 52.4094 37.3969C52.8111 37.3969 53.1367 37.7225 53.1367 38.1241V40.9773C53.1367 41.3789 52.8111 41.7045 52.4094 41.7045C52.0078 41.7045 51.6822 41.3789 51.6822 40.9773V38.1241Z" fill={theme.palette[down]}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M31.8638 22.6667C31.8638 21.9303 32.515 21.3334 33.3183 21.3334H36.2273C37.0306 21.3334 37.6818 21.9303 37.6818 22.6667V31.3334C37.6818 32.0698 37.0306 32.6667 36.2273 32.6667H33.3183C32.515 32.6667 31.8638 32.0698 31.8638 31.3334V22.6667Z" fill={theme.palette[down]}/>
      <path d="M34.0458 18.6667C34.0458 18.2985 34.3714 18 34.773 18C35.1747 18 35.5003 18.2985 35.5003 18.6667V21.3333C35.5003 21.7015 35.1747 22 34.773 22C34.3714 22 34.0458 21.7015 34.0458 21.3333V18.6667Z" fill={theme.palette[down]}/>
      <path d="M34.046 32.6667C34.046 32.2985 34.3716 32 34.7732 32C35.1749 32 35.5005 32.2985 35.5005 32.6667V35.3333C35.5005 35.7015 35.1749 36 34.7732 36C34.3716 36 34.046 35.7015 34.046 35.3333V32.6667Z" fill={theme.palette[down]}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M58.3182 11.0724C58.3182 10.0978 58.9694 9.30768 59.7727 9.30768H62.6817C63.485 9.30768 64.1362 10.0978 64.1362 11.0724V26.9531C64.1362 27.9277 63.485 28.7178 62.6817 28.7178H59.7727C58.9694 28.7178 58.3182 27.9277 58.3182 26.9531L58.3182 11.0724Z" fill={theme.palette[up]}/>
      <path d="M60.5002 6.71795C60.5002 6.32144 60.8258 6 61.2274 6C61.6291 6 61.9547 6.32144 61.9547 6.71795V9.58974C61.9547 9.98626 61.6291 10.3077 61.2274 10.3077C60.8258 10.3077 60.5002 9.98626 60.5002 9.58974V6.71795Z" fill={theme.palette[up]}/>
      <path d="M60.5003 28.4358C60.5003 28.0393 60.8259 27.7178 61.2276 27.7178C61.6292 27.7178 61.9548 28.0393 61.9548 28.4358V31.3076C61.9548 31.7041 61.6292 32.0255 61.2276 32.0255C60.8259 32.0255 60.5003 31.7041 60.5003 31.3076V28.4358Z" fill={theme.palette[up]}/>
      { active ? <rect x="0.75" y="0.75" width="94.5" height="46.2045" rx="2.55159" stroke="#D9E4E6" strokeWidth="1.5"/> : null }
    </svg>
  )
}

export default ChartColorIcon
