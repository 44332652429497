import { createSlice } from '@reduxjs/toolkit'
import { theme } from '@otsofintech/sofinx-ui/lib/style'

const themeSlice = createSlice({
  name: 'theme',
  initialState: theme,
  reducers: {
    setTheme: (state, action: { payload: typeof theme }) => action.payload,
  }
})

export const themeActions = { ...themeSlice.actions }
export default themeSlice.reducer