import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { UserAuth } from '@/utils/enum'
import { SFSVG, SFIconImage } from '@otsofintech/sofinx-ui'
import useResize from '@/hooks/useResize'
import joinIcon from '@/assets/images/freeze-color/join.svg'
import UserMenu from '../user-menu'
import SettingMenu from '../setting-menu'
import I18nSelector from '../i18n-selector'
import MobileNavigation from '../mobile-navigation'
import * as Styled from './index.style'
import NotifyBadge from './notify-badge'
import AppDownload from '../app-download'
import TutorialCreator from '@/components/user-tutorial/tutorial-creator'
import { isSofinqDomain } from '@/utils/helper'

interface HeaderBarProps {
  haveMessage: boolean
  handleOpen: () => void
}


const HeaderBar = ({
  haveMessage,
  handleOpen
}: HeaderBarProps) => {
  const { t, i18n } = useTranslation()

  const history = useHistory()
  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)
  const isDarkMode = useSelector((state: RootState) => state.setting.darkMode)
  const companyCustomizedMenu = useSelector((state: RootState) => state.companyCustomizedMenu)

  const { device } = useResize()

  // 在登入頁時顯示:register 其他頁顯示:login
  const directivePage = useMemo(() => history.location.pathname === '/login' ?
    {path: 'register' , label: 'registerAccount'} :
    {path: 'login' , label: 'logIn'}
  , [history.location.pathname])

  const navList = useMemo(() => (
    <>
      {/* <SFText key="meet" onClick={() => window.open('https://sofinx.com/', '__blank')} mr="24px" level={2} color="grey50" pointer>{t('meet')}</SFText> */}
      {
        !!companyCustomizedMenu.length && companyCustomizedMenu.map((item)=>{
          return(
            item.name ?
              <Styled.LinkItem href={item.url} key={item.id} target="_blank">
                {item.name}
              </Styled.LinkItem>
              : null
          )
        })
      }
      <Styled.NavItem to="/signal">{t('provider')}</Styled.NavItem>
      {isSofinqDomain() || <Styled.NavItem to="/competition-explore" id="header-navigation-competition">{t('competition')}<SFSVG width="40px" height="16px" src={joinIcon} ml="4px" /></Styled.NavItem> }
    </>
  ), [i18n.language, isLogin, companyCustomizedMenu])

  const panelList = useMemo(() => (
    isLogin ?
      (
        <>
          <UserMenu />
          <Styled.Split />
          <NotifyBadge haveMessage={haveMessage} handleOpen={handleOpen} />
          <Styled.Split />
          {/* <SFSVG id="install-btn" src={devicesIcon} color="grey200" pointer/> */}
          {isSofinqDomain() ? null:<><AppDownload />
            <Styled.Split /></>}
          <I18nSelector />
          <Styled.Split />
          <SettingMenu />
        </>
      ) :
      (
        <>
          <Styled.LoginLink to={{ pathname: `/${directivePage.path}`, state:{redirectPath: history.location.pathname} }}>{t(directivePage.label)}</Styled.LoginLink>
          <Styled.Split />
          {isSofinqDomain() ? null:<><AppDownload />
            <Styled.Split /></>}
          <I18nSelector />
          <Styled.Split />
          <SettingMenu />
        </>
      )
  ), [i18n.language, isLogin, directivePage, haveMessage])

  return TutorialCreator({
    step: isSofinqDomain() ? 4 : 5,
    component:<Styled.Header isLogin={isLogin}>
      <div style={{ width: '100px', height: '35px' }}>
        <SFIconImage
          src={`/${ isDarkMode ? 'dark_logo' : 'light_logo' }?${Date.now()}`}
          onClick={() => history.push('/')}
          style={{ width: '100px', height: '35px', cursor: 'pointer' }}
        />
      </div>

      {/* 導覽列 */}
      { device !== 'mobile' ? <Styled.NavMenu>{ navList }</Styled.NavMenu> : <MobileNavigation /> }

      {/* 個人控制列 */}
      <Styled.Panel>{ panelList }</Styled.Panel>
    </Styled.Header>
  })
}

export default HeaderBar
