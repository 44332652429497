import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetFollowRobotList } from '@/services/api-helper/follow'
import { StrategyOrderby, FollowType } from '@/utils/enum'

const initRobot = (data?: Follow.RobotList.Res<FollowType>) => {
  return {
    robots: data?.robots || [] as Common.Robot<FollowType>[],
    total: data?.total || 0
  }
}
// extraReducers
const updateRobot = createAsyncThunk('robot/list', async (query?: Strategy.Get.Request<StrategyOrderby>) => {
  const result = await GetFollowRobotList()
  return result.isError === false ? result.value : initRobot()
})

const robotSlice = createSlice({
  name: 'robot',
  initialState: initRobot(),
  reducers: {
    clearRobot: () => initRobot(),
  },

  extraReducers: (builder) => {
    builder.addCase(updateRobot.fulfilled, (state, action) => {
      if (action.payload) return initRobot(action.payload)
    })
  },
})

export const robotActions = { ...robotSlice.actions, updateRobot}
export default robotSlice.reducer
