
/**
 * COMPLETE: 完整跟隨 /
 * STRATEGY: 策略機器人)
 */
export enum FollowType {
  COMPLETE = 'COMPLETE',
  STRATEGY = 'STRATEGY',
}

/**
 * ZERO: 零持倉跟單 /
 * BALANCED: 持倉均衡跟單
 */
export enum PositionFollowType {
  ZERO = 'ZERO_POSITION',
  BALANCED = 'BALANCED_POSITION',
}

/**
 * DONT_COVER_POSITION: 不補倉 /
 * COVER_POSITION_MARKET: 按市價補倉)
 */
export enum EntryMode {
  DONT_COVER_POSITION = 'DONT_COVER_POSITION',
  COVER_POSITION_MARKET = 'COVER_POSITION_MARKET',
}

/**
 * Guest: 未登入 /
 * Member: 登入(權限1)
 */
export enum UserAuth {
  GUEST = 'GUEST',
  MEMBER = 'MEMBER',
}

/**
 * BALANCE: 真實資金 /
 * DEMO_BALANCE: 模擬資金
 */
export enum WalletMode {
  BALANCE = 1,
  DEMO_BALANCE = 0,
}

/**
 * 策略清單排序方式
 */
export enum StrategyOrderby {
  SIGNAL = 'signal',
  SYMBOL = 'symbol',
  REVERSE = 'reverse',
}

export enum SignTypes {
  Email = 'Email',
  Phone = 'Phone',
}


// 資產
export enum WalletKind {
  real = 'real',
  demo = 'demo',
  token = 'token',
}

export enum WalletType {
  DEPOSIT = 'DEPOSIT',
  DEPOSIT_WIRE = 'DEPOSIT_WIRE',
  DEPOSIT_PAYMENT = 'DEPOSIT_PAYMENT',
  DEPOSIT_CRYPTO = 'DEPOSIT_CRYPTO',
  DEPOSIT_HDWALLET = 'DEPOSIT_HDWALLET',
  WITHDRAW_WIRE = 'WITHDRAW_WIRE',
  WITHDRAW_CRYPTO = 'WITHDRAW_CRYPTO',
  ROBOT_START = 'ROBOT_START',
  ROBOT_UPDATE = 'ROBOT_UPDATE',
  ROBOT_STOP = 'ROBOT_STOP',
  ROBOT_PROFIT_SHARE = 'ROBOT_PROFIT_SHARE',
  COMPETITION_JOINED = 'COMPETITION_JOINED',
  COMPETITION_TRANSFER = 'COMPETITION_TRANSFER',
  COMPETITION_REWARD = 'COMPETITION_REWARD',
  DEPOSIT_REAL_BALANCE_BY_BROKER = 'DEPOSIT_REAL_BALANCE_BY_BROKER',
  DEPOSIT_DEMO_BALANCE_BY_BROKER = 'DEPOSIT_DEMO_BALANCE_BY_BROKER',
  WITHDRAW_REAL_BALANCE_BY_BROKER = 'WITHDRAW_REAL_BALANCE_BY_BROKER',
  APPLY_DEMO_BALANCE = 'APPLY_DEMO_BALANCE',
  REWARD_REGISTERED = 'REWARD_REGISTERED',
  REWARD_FIRST_COMPLETE_ROBOT_CREATE = 'REWARD_FIRST_COMPLETE_ROBOT_CREATE',
  REWARD_FIRST_STRATEGY_ROBOT_CREATE = 'REWARD_FIRST_STRATEGY_ROBOT_CREATE',
  REWARD_INTRODUCE = 'REWARD_INTRODUCE',
  WITHDRAW_TOKEN_BY_BROKER = 'WITHDRAW_TOKEN_BY_BROKER',
}


export enum TimePeriod {
  '7d' = '7d',
  '30d' = '30d',
  '90d' = '90d',
  '180d' = '180d',
  'All' = 'All'
}

export enum OrderRejectedReason {
  NOT_ENOUGH_FREE_MARGIN = 'NOT_ENOUGH_FREE_MARGIN', // IV保證金不足
  TRADING_CONDITIONS_DO_NOT_ALLOW = 'TRADING_CONDITIONS_DO_NOT_ALLOW', // IV跟單限制(手數、時間)
  FIX_REJECTED = 'FIX_REJECTED', // 被上游LP拒絕
  NOT_DURING_TRADING_TIME = 'NOT_DURING_TRADING_TIME', // 非交易時間 reason 1
  NOT_DURING_TRADING_TIME_LIMIT = 'NOT_DURING_TRADING_TIME_LIMIT', // 非交易時間 reason 2
}

export enum Platform {
  MT4 = 'MT4',
  MT5 = 'MT5',
  BINANCE = 'BINANCE',
}