import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { UserAuth } from '@/utils/enum'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Collapse from '@material-ui/core/Collapse'
import ArrowLeftIcon from '@/assets/images/arrow-left.svg'
import StrategyIcon from '@/assets/images/strategy-background.svg'
import RobotIcon from '@/assets/images/robot.svg'
import CompetitionIcon from '@/assets/images/competition.svg'
import { SFSVG, SFText } from '@otsofintech/sofinx-ui'
import AppsIcon from './apps-icon'
import * as Styled from './index.style'
import FavoriteIcon from '@/assets/images/favorite-background.svg'
import TutorialCreator from '../../../components/user-tutorial/tutorial-creator'

const MenuList = ({ handleClose }: { handleClose: () => void }) => {
  const { t } = useTranslation()

  const strategyAmount = useSelector((state: RootState) => state.strategy.length)
  const robotAmount = useSelector((state: RootState) => state.robot.total)
  const competitionAmount = useSelector((state: RootState) => state.competition.competitions.filter(item => item.status !== 'ENDED' && item.status !== 'TERMINATED').length)

  const list = [
    { label: t('signalCollection'), name: 'favorite', icon: FavoriteIcon },
    { label: t('myStrategy'), name: 'strategy', icon: StrategyIcon, badge: strategyAmount ? <Styled.Badge>{ strategyAmount }</Styled.Badge> : null },
    { label: t('tradeRobot'), name: 'robot', icon: RobotIcon, badge: robotAmount ? <Styled.Badge>{ robotAmount }</Styled.Badge> : null },
    { label: t('personalCompetition'), name: 'competition', icon: CompetitionIcon, badge: competitionAmount ? <Styled.Badge>{ competitionAmount }</Styled.Badge> : null },
  ]

  return (
    <Styled.MenuContent>
      { list.map(item => (
        <Styled.MenuItem
          key={item.name}
          to={`/${item.name}`}
          activeClassName="active-menu"
          onClick={handleClose}
        >
          <SFSVG src={item.icon} color="grey200" />
          <SFText ml="12px" flexGrow="1" level={2} color="grey40">{ item.label }</SFText>
          { item.badge }
        </Styled.MenuItem>
      ))}
    </Styled.MenuContent>
  )
}

const AsideMenu = () => {
  const location = useLocation()
  const history = useHistory()
  const isGuest = useSelector((state: RootState) => state.auth === UserAuth.GUEST)

  const [isOpen, setOpen] = useState(false)
  const [clickAwayEnabled, setClickAwayEnabled] = useState(true)
  const handleClickAway = () => {
    if(clickAwayEnabled) setOpen(false)
  }
  const handleClick = () => setOpen(prev => !prev)

  const hideClass = useMemo(() => {
    const pathList = location.pathname.split('/')
    return isGuest || (pathList.length <= 2 && ['', 'anlysis', 'signal'].includes(pathList[1])) ? 'hide' : ''
  }, [location])

  const backLastLevel = () => {
    history.goBack()
  }

  const tutorialAction = () => {
    setOpen(true)
    setClickAwayEnabled(false)
  }

  const tutorialClear = () => {
    setOpen(false)
    setClickAwayEnabled(true)
  }

  return TutorialCreator({
    step: 2,
    actionFunc: tutorialAction,
    clearFunc: tutorialClear,
    component: 
    <Styled.Container>
      {/* Back */}
      <Styled.ActionButton className={hideClass} onClick={backLastLevel}>
        <SFSVG src={ArrowLeftIcon} color="grey30" />
      </Styled.ActionButton>

      {/* Apps */}
      { !isGuest &&
      <ClickAwayListener onClickAway={handleClickAway}>
        <Styled.NavWrapper>
          <Styled.ActionButton isOpen={isOpen} onClick={handleClick}>
            <AppsIcon active={isOpen} />
          </Styled.ActionButton>

          <Styled.Content id="aside-menu-content">
            <Collapse in={isOpen}>
              <MenuList handleClose={handleClickAway} />
            </Collapse>
          </Styled.Content>

        </Styled.NavWrapper>
      </ClickAwayListener>
      }
    </Styled.Container>
  })
}

export default AsideMenu
