import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { UserAuth } from '@/utils/enum'
import { SFText, SFSVG } from '@otsofintech/sofinx-ui'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import appsIcon from '@/assets/images/apps.svg'
import signalIcon from '@/assets/images/signal.svg'
import globalIcon from '@/assets/images/global.svg'
import competitionIcon from '@/assets/images/competition.svg'
import strategyIcon from '@/assets/images/strategy.svg'
import robotIcon from '@/assets/images/robot.svg'
import CollectionIcon from '@/assets/images/freeze-color/collection.svg'
// Meet,
import { AppsImg, Wrap, Content, NavItem, Badge, LinkItem } from './style'
import { useTranslation } from 'react-i18next'
import { isSofinqDomain } from '@/utils/helper'

const MobileNavigation = () => {
  const { t } = useTranslation()
  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)

  const strategyAmount = useSelector((state: RootState) => state.strategy.length)
  const robotAmount = useSelector((state: RootState) => state.robot.total)
  const competitionAmount = useSelector((state: RootState) => state.competition.competitions.filter(item => item.status !== 'ENDED' && item.status !== 'TERMINATED').length)
  const companyCustomizedMenu = useSelector((state: RootState) => state.companyCustomizedMenu)

  const list = [
    { label: t('signalCollection'), name: 'favorite', icon: CollectionIcon },
    { label: t('myStrategy'), name: 'strategy', icon: strategyIcon, badge: strategyAmount ? <Badge>{ strategyAmount }</Badge> : null },
    { label: t('tradeRobot'), name: 'robot', icon: robotIcon, badge: robotAmount ? <Badge>{ robotAmount }</Badge> : null },
    { label: t('personalCompetition'), name: 'competition', icon: competitionIcon, badge: competitionAmount ? <Badge>{ competitionAmount }</Badge> : null },
  ]
  const [isOpen, setIspOpen] = useState(false)
  const handleToggle = () => setIspOpen(prev => !prev)
  const handleClickAway = () => setIspOpen(false)

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <AppsImg onClick={handleToggle}>
        <SFSVG src={appsIcon} color={isOpen ? 'primary' : 'grey200'} />

        <Wrap isOpen={isOpen}>
          <Content>
            {/* <Meet onClick={() => window.open('https://sofinx.com/', '__blank')}>
              <SFSVG mr="12px" src={globalIcon} color="grey200" />
              <SFText level={2} color="grey40">{t('meet')}</SFText>
            </Meet> */}
            {
              !!companyCustomizedMenu.length && companyCustomizedMenu.map((item)=>{
                return(
                  item.name ?
                    <LinkItem href={item.url} key={item.id} target="_blank">
                      <SFSVG mr="12px" src={globalIcon} color="grey200" />
                      <SFText level={2} color="grey40">{item.name}</SFText>
                    </LinkItem>
                    : null
                )
              })
            }
            <NavItem to="/signal">
              <SFSVG mr="12px" src={signalIcon} color="grey200" />
              <SFText level={2} color="grey40">{t('provider')}</SFText>
            </NavItem>
            {!isSofinqDomain() && <NavItem to="/competition-explore">
              <SFSVG mr="12px" src={competitionIcon} color="grey200" />
              <SFText level={2} color="grey40">{t('competition')}</SFText>
            </NavItem>}
          </Content>

          { isLogin &&
            <Content>
              { list.map(item => (
                <NavItem key={item.name} to={`/${item.name}`}>
                  <SFSVG src={item.icon} color="grey200" />
                  <SFText ml="12px" level={2} color="grey40">{ item.label }</SFText>
                  { item.badge }
                </NavItem>
              )) }
            </Content>
          }
        </Wrap>
      </AppsImg>

    </ClickAwayListener>
  )
}

export default MobileNavigation
