import { useState } from 'react'
import AssetsBar from '../assets-bar'
// import ZohoSalesIQ , { salesIqLocationMap } from '../zoho-sales-iq'

interface FooterProps {
  isLogin: boolean
}

const Footer = ({
  isLogin,
}: FooterProps) => {

  const [isOpenAssetBar, setIsOpenAssetsBar] = useState(false)

  /// zoho-sales-iq 位置
  // const salesIqLocation: keyof typeof salesIqLocationMap = useMemo(() => {
  //   if(!isLogin) return 'default'
  //   else if(!isOpenAssetBar) return 'withAssetsBar'
  //   else return 'withAssetsBarOpen'
  // }, [isLogin, isOpenAssetBar])

  return (
    <>
      {/* <ZohoSalesIQ location={salesIqLocation} /> */}
      { isLogin && <AssetsBar isOpen={isOpenAssetBar} setIsOpen={setIsOpenAssetsBar} />}
    </>
  )
}

export default Footer
