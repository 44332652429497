import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetCompanyInfoService } from '@/services/api-helper/config'
import { CompanyInfo } from '@/utils/class'

// const initState = {
//   name: '',
//   email: '',
//   countryCode: '',
//   phone: '',
// }

// extraReducers
const updateCompanyInfo = createAsyncThunk('config/company', async () => {
  const result = await GetCompanyInfoService()
  return result.isError === false ? CompanyInfo(result.value) : CompanyInfo()
})

const companyInfoSlice = createSlice({
  name: 'companyInfo',
  initialState: CompanyInfo(),
  reducers: {
    clearCompanyInfo: (state) => {
      return CompanyInfo()
    }
  },

  extraReducers: (builder) => {
    builder.addCase(updateCompanyInfo.fulfilled, (state, action) => action.payload || CompanyInfo())
  },
})

export const companyInfoActions = { ...companyInfoSlice.actions, updateCompanyInfo}
export default companyInfoSlice.reducer
