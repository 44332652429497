import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetBrokerSubscriptions } from '@/services/api-helper/subscriptions'
const initialState: Common.Broker[] = []

const updateSubscriptions = createAsyncThunk('subscriptions/list', async () => {
  const result = await GetBrokerSubscriptions()
  if(!result.isError) return result.value
})

const brokerSubscriptionsSlice = createSlice({
  name: 'brokerSubscriptions',
  initialState,
  reducers: {
    setBrokerSubscriptions: (state, action) => {
      const { feature, key, value }= action.payload
      return state.map(item => {
        if (item.feature === feature) {
          return {...item, [key]: value}
        }
        return item
      })
    },
    clearBrokerSubscriptions: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(updateSubscriptions.fulfilled, (state, action) => action.payload || initialState)
  }
})

export const brokerSubscriptionsActions = { ...brokerSubscriptionsSlice.actions, updateSubscriptions}
export default brokerSubscriptionsSlice.reducer