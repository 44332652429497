import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetConfigSymolsService } from '@/services/api-helper/config'

const initSymbols: Common.ConfigSymbol[] = []

// extraReducers
const updateConfigSymbol = createAsyncThunk('config/symbol', async () => {
  const result = await GetConfigSymolsService()
  return result.isError === false ? result.value || [] : []
})

const symbolsSlice = createSlice({
  name: 'symbols',
  initialState: initSymbols,
  reducers: {
    clearConfigSymbol: () => initSymbols
  },

  extraReducers: (builder) => {
    builder.addCase(updateConfigSymbol.fulfilled, (state, action) => action.payload || [])
  },
})

export const symbolsActions = { ...symbolsSlice.actions, updateConfigSymbol}
export default symbolsSlice.reducer
