import { toCamel } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './services'

// 取得通知清單
export const GetNotifyListService = (): ApiService<Common.NotifyPayload[]> => {
  return request.get('/notify')
    .then(res => {
      const data = res.data.notifications || []
      data.forEach((item: Omit<Common.NotifyPayload, 'data'> & { data: string }) => item.data = JSON.parse(item.data))
      return { isError: false, value: toCamel(data) }
    })
    .catch(err => {
      apiErrorMsg('GetNotifyListService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 刪除通知
export const PutNotifyService = (body: Notify.Put.Resquest): ApiService<boolean> => {
  let parameterStr = ''
  if (body.id.length) {
    let parameters = body.id.map((item => `id=${item}`))
    parameterStr = `?${parameters.join('&')}`
  }

  return request.delete(`/notify${parameterStr}`)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('PutNotifyService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
