import { userinfoActions } from './slices/userInfoReducer'
import { authActions } from './slices/authReducer'
import { symbolsActions } from './slices/symbolsReducer'
import { strategyActions } from './slices/strategyReducer'
import { walletActions } from './slices/walletReducer'
import { robotActions } from './slices/robotReducer'
import { competitionsActions } from './slices/competitionReducer'
import { settingActions } from './slices/settingReducer'
import { themeActions } from './slices/themeReducer'
import { companyInfoActions } from './slices/companyInfoReducer'
import { competitionWalletActions } from './slices/competitionWalletReducer'
import { wsStateActions } from './slices/wsStateReducer'
import { favoriteSignalIdsAction } from './slices/favoriteSignalIdsReducer'
import { creditDepositActions } from './slices/creditDepositReducer'
import { tutorialStepActions } from './slices/tutorialStepReducer'
import { brokerSubscriptionsActions } from './slices/brokerSubscriptionsReducer'
import { companyCustomizedMenuActions } from './slices/companyCustomizedMenuReducer'


export const setUserInfo = userinfoActions.setUserInfo

export const clearUserInfo = userinfoActions.clearUserInfo

export const updateUserinfo = userinfoActions.updateUserinfo

export const setAuth = authActions.setAuth

export const clearAuth = authActions.clearAuth

export const updateConfigSymbol = symbolsActions.updateConfigSymbol

export const clearConfigSymbol = symbolsActions.clearConfigSymbol

export const updateStrategy = strategyActions.updateStrategy

export const clearStrategy = strategyActions.clearStrategy

export const updateUserWallet = walletActions.updateUserWallet

export const clearWallet = walletActions.clearWallet

export const updateRobot = robotActions.updateRobot

export const clearRobot = robotActions.clearRobot

export const updateCompetition = competitionsActions.updateCompetition

export const clearCompetition = competitionsActions.clearCompetition

export const setBrowserNotify = settingActions.setBrowserNotify

export const setDarkMode = settingActions.setDarkMode

export const setChartColor = settingActions.setChartColor

export const setTheme = themeActions.setTheme

export const updateCompanyInfo = companyInfoActions.updateCompanyInfo

export const clearCompanyInfo = companyInfoActions.clearCompanyInfo

export const updateCompanyCustomizedMenu = companyCustomizedMenuActions.updateCompanyCustomizedMenu

export const clearCompanyCustomizedMenu = companyCustomizedMenuActions.clearCompanyCustomizedMenu

export const updateCompetitionWallet = competitionWalletActions.updateCompetitionWallet

export const clearCompetitionWallet = competitionWalletActions.clearCompetitionWallet

export const enableWsState = wsStateActions.enable

export const disableWsState = wsStateActions.disable

export const updateFavortieSignalIds = favoriteSignalIdsAction.updateFavortieSignalIds

export const updateCreditDeposit = creditDepositActions.updateCreditDeposit
export const clearCreditDeposit = creditDepositActions.clearCreditDeposit

export const updateTutorialStep = tutorialStepActions.setTutorialStep
export const clearTutorialStep = tutorialStepActions.clearTutorialStep

export const updateBrokerSubscriptions = brokerSubscriptionsActions.updateSubscriptions
export const clearBrokerSubscriptions = brokerSubscriptionsActions.clearBrokerSubscriptions
export const setBrokerSubscriptions = brokerSubscriptionsActions.setBrokerSubscriptions
