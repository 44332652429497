import { toCamel, toUnderline, deepCopy } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './services'
import { StrategyOrderby } from '@/utils/enum'


// 取得策略清單
export const GetStrategyListService = (query?: Strategy.Get.Request<StrategyOrderby>): ApiService<Strategy.Get.Response> => {
  const params = toUnderline(deepCopy(query))
  return request.get('/strategy', { params })
    .then(res => ({ isError: false, value: toCamel(res.data.strategies) || [] }))
    .catch(err => {
      apiErrorMsg('GetStrategyListService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 試算策略保證金
export const PostTrialMarginService = (body: Strategy.TrialMargin.Req): ApiService<Strategy.TrialMargin.Res> => {
  return request.post('/strategy/trial_margin', toUnderline(deepCopy(body)))
    .then(res => ({ isError: true, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PostTrialMarginService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 加入策略
export const PostStrategyService = (body: Strategy.Post.Request): ApiService<Strategy.Post.Response> => {
  return request.post('/strategy', toUnderline(deepCopy(body)))
    .then(res => ({ isError: false, value: toCamel(res.data.strategy) }))
    .catch(err => {
      apiErrorMsg('PostStrategyService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 調整策略
export const PutStrategyService = (body: Strategy.Put.Request): ApiService<Strategy.Put.Response> => {
  return request.put(`/strategy/${body.strategyId}`, toUnderline(deepCopy(body)))
    .then(res => ({ isError: false, value: toCamel(res.data.strategy) }))
    .catch(err => {
      apiErrorMsg('PutStrategyService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 刪除策略
export const DelStrategyService = (body: Strategy.Del.Request): ApiService<true> => {
  return request.delete(`/strategy/${body.strategyId}`)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('DelStrategyService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
