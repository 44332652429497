import styled from 'styled-components'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { setUserInfo } from '@/store/actions'
import { PutUserLangService } from '@/services/api-helper/user'
import { SFSVG, SFText } from '@otsofintech/sofinx-ui'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Collapse from '@material-ui/core/Collapse'
import { languageList } from '@/assets/locales/i18n'
import { UserAuth } from '@/utils/enum'
import EarthIcon from '@/assets/images/earth.svg'

const I18nSVG = styled(SFSVG)`
`

const I18nBtn = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  &:hover ${ I18nSVG } {
    fill: ${ props => props.theme.palette.primary };
  }
`

const Content = styled.div`
  position: absolute;
  top: 52px;
  right: 0px;
  box-shadow: ${ props => props.theme.shadow[400] };
  border-radius: 8px;
  background-color: ${ props => props.theme.palette.blank };
`

const OptionLabel = styled(SFText)`
  padding: 8px 16px;
  width: 96px;
  transition: all .2s;

  &:hover {
    color: ${ props => props.theme.palette.grey40 };
    background: ${ props => props.theme.palette.primary }14;
  }
`

const I18nSelector = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const currentLang = useMemo(() => {
    return languageList.find(item => item.value === i18n.language)?.short || ''
  }, [i18n.language])

  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)
  const userInfo = useSelector((state: RootState) => state.userInfo)

  const handleOption = (lang: string) => {
    handleClose()
    localStorage?.setItem('language', lang)
    i18n.changeLanguage(lang)

    if (isLogin) {
      PutUserLangService({ language: lang })
      dispatch(setUserInfo({ ...userInfo, language: lang }))
    }
  }

  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = () => setIsOpen(prev => !prev)
  const handleClose = () => setIsOpen(false)

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <I18nBtn>
        <I18nSVG src={EarthIcon} onClick={handleToggle} color={isOpen ? 'primary' : 'grey50'} width='24px' height='24px' pointer/>

        <Content>
          <Collapse in={isOpen}>
            <div style={{ padding: '8px 0 8px 0'}}>
              {languageList.map(item => (
                <OptionLabel
                  display="inline-block"
                  level={2}
                  color={ currentLang === item.short ? 'grey60' : 'grey40' }
                  textAlign="center"
                  key={item.value}
                  onClick={() => handleOption(item.value)}
                  pointer
                >
                  {item.text}
                </OptionLabel>
              ))}
            </div>
          </Collapse>
        </Content>
      </I18nBtn>
    </ClickAwayListener>
  )
}

export default I18nSelector
