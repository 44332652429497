import { GetFavoriteSignalIdsService } from '@/services/api-helper/favorite'
import { createAsyncThunk, createSlice} from '@reduxjs/toolkit'

const initialState: string[] = []

const updateFavortieSignalIds = createAsyncThunk('updateFavortieSignalIds', async () => {
  const result = await GetFavoriteSignalIdsService()
  return result.isError === false ? result.value.signalIds : initialState
})

export const favoriteSignalIdsSlice = createSlice({
  name: 'favoriteSignalIds',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(updateFavortieSignalIds.fulfilled, (state, action) => action.payload || initialState)
  },
  
})
export const favoriteSignalIdsAction = { ...favoriteSignalIdsSlice.actions, updateFavortieSignalIds}
export default favoriteSignalIdsSlice.reducer