import { deepCopy, toCamel, toUnderline } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from '@/services/api-helper/services'
import { getQueryString } from '@/utils/helper'

// TODO: 暫時增加test switch供後端測試增量計算
// 搜尋關鍵字： testSwitch
const testSwitch = '?test_switch=true'

// 取得所有tag資訊&順序
export const GetFavoriteTagsService = (): ApiService<Favorite.GetFavoriteTags.Res> =>{
  return request.get('/user/tag')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetFavoriteTagsService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// user收藏的訊號源id
export const GetFavoriteSignalIdsService = (): ApiService<Favorite.GetFavoriteSignalIds.Res> => {
  return request.get('/user/tag/signal/id_list')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetFavoriteSignalIdsService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 查看該訊號源id所屬sf的哪些tag
export const GetTagsOfSignalIdService = (signalId: string): ApiService<Favorite.GetTagsOfSignalIdService.Res> => {
  return request.get(`/user/tag/signal/${signalId}/in_tags`)
    .then(res=>({isError: false, value: toCamel(res.data)}))
    .catch(err => {
      apiErrorMsg('GetTagsOfSignalIdService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

//新增tag
export const AddNewTagService = (query: Favorite.AddNewTagService.Req) => {
  return request.post('/user/tag', toUnderline(query))
    .then(res => ({ isError: false, value: res.data.id }) as const)
    .catch (err =>{
      apiErrorMsg('AddNewTagService',err.response?.data?.error)
      return {isError: true, value: err.response?.data?.error}
    })
}

//刪除tag
export const DeleteTagService = (tagId: string) => {
  return request.delete(`/user/tag/${tagId}`)
    .then(res => ({ isError: false, value: true }) as const)
    .catch (err =>{
      apiErrorMsg('DeleteTagService',err.response?.data?.error)
      return {isError: true, value: err.response?.data?.error}
    })
}

// tag新增訊號源
export const AddSignalToTagService = (tagId:string, signalId:string) => {
  return request.post(`/user/tag/${tagId}/signal/${signalId}`)
    .then(res => ({ isError: false, value: true }) as const)
    .catch (err =>{
      apiErrorMsg('AddSignalToTagService',err.response?.data?.error)
      return {isError: true, value: err.response?.data?.error}
    })
}

// tag刪除訊號源
export const RemoveSignalFromTagService = (tagId: string, signalId: string) => {
  return request.delete(`/user/tag/${tagId}/signal/${signalId}`)
    .then(res => ({ isError: false, value: true }) as const)
    .catch (err =>{
      apiErrorMsg('RemoveSignalFromTagService',err.response?.data?.error)
      return {isError: true, value: err.response?.data?.error}
    })
}

// 修改tag名稱
export const UpdateTagNameService = (tagId: string, query: Favorite.UpdateTagNameService.Req) => {
  return request.patch(`/user/tag/${tagId}`, toUnderline(query))
    .then(res => ({ isError: false, value: res.data.id }) as const)
    .catch (err =>{
      apiErrorMsg('UpdateTagNameService',err.response?.data?.error)
      return {isError: true, value: err.response?.data?.error}
    })
}

// 該tag_id底下的signal資訊
export const GetSignalsFromFavoriteTagService = (tagId: string, query: Favorite.GetSignalsFromFavoriteTagService.Req): ApiService<Favorite.GetSignalsFromFavoriteTagService.Res> => {
  const paramStr = getQueryString(toUnderline(deepCopy(query)))
  // return request.get(`/user/tag/${tagId}/signal?${paramStr}`)
  return request.get(`/user/tag/${tagId}/signal${testSwitch}&${paramStr}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetSignalsFromFavoriteTagService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

