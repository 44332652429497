
import * as Styled from './index.style'
import DownloadAppIcon from '@/assets/images/download-app.svg'
import { SFButton, SFText } from '@otsofintech/sofinx-ui'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import QRCode from 'react-qr-code'


const urlOrigin = window.location.origin

const AppDownload = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const menuNode = useRef<HTMLDivElement>(null)
  const handleClick = () => {
    menuNode.current?.classList.add('close')
    history.push('/app-download')
    setTimeout(() => { 
      menuNode.current?.classList.remove('close') 
    }, 300)
  }
  return (
    <Styled.Container>
      <Styled.Icon src={DownloadAppIcon} width='24px' height='24px' pointer color='grey50' />
      <Styled.MenuContent ref={menuNode} className="menu">
        <QRCode size={160} value={`${urlOrigin}/app-download`} style={{marginTop: '24px'}}/>
        
        <SFText level={2} fontWeight={600} color='grey50' mt='16px'>{t('scanToDownload')}</SFText>
        <SFText level={2} fontWeight={600} color='grey50' mb='16px'> iOS & Android APP</SFText>
        <SFButton themecolor='primary' onClick={handleClick }>{t('moreDownload')}</SFButton>
      </Styled.MenuContent>
    </Styled.Container>
  )
}

export default AppDownload