import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import styled from 'styled-components'
import { SFSVG } from '@otsofintech/sofinx-ui'
import Apps from '@/assets/images/apps.svg'

interface AppsIconProps {
  active: boolean
}

const Icons = styled(SFSVG)<{ havebadge: number }>`
  & rect[sign=circle] {
    fill: ${ props => props.havebadge ? props.theme.palette.primary : props.theme.palette.grey30 };
  }
`

const AppsIcon = ({
  active,
}: AppsIconProps) => {
  const { strategyAmount, robotAmount } = useSelector((state: RootState) => ({ strategyAmount: state.strategy.length, robotAmount: state.robot.total }))

  return <Icons havebadge={strategyAmount || robotAmount} src={Apps} color={ active ? 'primary' : 'grey30' } />
}

export default AppsIcon
