import { toCamel, toUnderline, deepCopy } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './services'

// 以Email註冊成為會員 (請求 email 註冊驗證碼)
export const RegisterByEmailService = (query: Auth.RegisterByEmail.Request): ApiService<Auth.RegisterByEmail.Response> => {
  const params = toUnderline(deepCopy(query))
  if (!params.referrer) delete params.referrer

  return request.post('/auth/register_by_email', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('RegisterByEmailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 以Phone註冊成為會員 (請求 phone 註冊驗證碼)
export const RegisterByPhoneService = (query: Auth.RegisterByPhone.Request): ApiService<Auth.RegisterByPhone.Response> => {
  const params = toUnderline(deepCopy(query))
  if (!params.referrer) delete params.referrer

  return request.post('/auth/register_by_phone', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('RegisterByPhoneService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 登入 (請求登入驗證碼)
export const LoginService = (query: Auth.Login.Request): ApiService<Auth.Login.Response> => {
  const params = toUnderline(deepCopy(query))
  return request.post('/auth/login', params)
    .then(res => ({ isError: false, value: toCamel(res.data) }) as const)
    .catch(err => {
      apiErrorMsg('LoginService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 確認是否登入成功
export const GetAuthLoggedIn = (): ApiService<Auth.GetCountryCode.Response> =>{
  return request.get('/auth/login')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetAuthLoggedIn', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 綁定email
export const BindEmailService = (query: Auth.BindEmail.Request): ApiService<boolean> => {
  const params = toUnderline(deepCopy(query))
  return request.post('/auth/email', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('BindEmailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 綁定phone
export const BindPhoneService = (query: Auth.BindPhone.Request): ApiService<boolean> => {
  const params = toUnderline(deepCopy(query))
  return request.post('/auth/phone', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('BindPhoneService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 發送 login 驗證碼
export const SendVerifyTokenService = (query: Auth.SendVerify.Request): ApiService<boolean> => {
  return request.post('/auth/verify', query)
    .then(res => ({ isError: false, value: toCamel(res.data.user) }))
    .catch(err => {
      apiErrorMsg('SendVerifyTokenService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 確認驗證碼
export const VerifyTokenService = (query:Auth.Verify.Request): ApiService<Auth.Verify.Response> => {
  const params = toUnderline(deepCopy(query))
  return request.patch('/auth/verify', params)
    .then(res => ({ isError: false, value: toCamel(res.data.user) }))
    .catch(err => {
      apiErrorMsg('VerifyTokenService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 綁定email驗證碼
export const BindEmailVerifyService = (query: Auth.Verify.Request): ApiService<boolean> => {
  const params = toUnderline(deepCopy(query))
  return request.patch('/auth/email/verify', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('BindEmailVerifyService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 綁定phone驗證碼
export const BindPhoneVerifyService = (query: Auth.Verify.Request): ApiService<boolean> => {
  const params = toUnderline(deepCopy(query))
  return request.patch('/auth/phone/verify', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('BindPhoneVerifyService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 啟用 google auth
export const EnableGoogleAuthService = (query: Auth.EnableGoogleAuth.Request): ApiService<boolean> => {
  return request.post('/auth/googleauth/enable', query)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('EnableGoogleAuthService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 以驗證碼 啟用google auth
export const VerifyEnableGoogleAuthService = (query:Auth.Verify.Request): ApiService<Auth.GoogleAuth.VerifyEnableGoogleAuth.Response> => {
  return request.patch('/auth/googleauth/enable', query)
    .then(res => ({ isError: false, value: res.data }))
    .catch(err => {
      apiErrorMsg('VerifyEnableGoogleAuthService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 確認 google auth 是否啟用 (Get GoogleAuth Activated Result)
export const CheckGoogleAuthActivated = (): ApiService<Auth.GoogleAuth.CheckGoogleAuthActivated.Response> =>{
  return request.get('/auth/googleauth/activated_query')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('CheckGoogleAuthActivated', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 確認 google auth 驗證碼 (GoogleAuth Verify without Secret)
export const VerifyGoogleAuthService = (query: Auth.GoogleAuth.VerifyGoogleAuth.Request): ApiService<boolean> => {
  const params = toUnderline(deepCopy(query))
  return request.patch('/auth/googleauth/verify_no_secret', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('VerifyGoogleAuthService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 驗證 google auth 一次性密碼 （未來可能會被併入VerifyGoogleAuthService）
export const VerifyGoogleAuthService2 = (query: Auth.GoogleAuth.VerifyGoogleAuth.Request): ApiService<boolean> => {
  return request.patch('/auth/googleauth/verify', query)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('VerifyGoogleAuthService2', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 停用 google auth
export const DisableGoogleAuthService = (query:Auth.Verify.Request): ApiService<boolean> => {
  return request.patch('/auth/googleauth/disable', query)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('DisableGoogleAuthService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 登出
export const LogoutService = (): ApiService<Auth.Logout.Response> => {
  return request.delete('/auth/logout')
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('LogoutService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 創建Websocket連接認證token，認證後才可訂閱 private 頻道，token有效時間為 10 秒
export const WsTokenService = (): ApiService<Auth.WebSocketToken.Response> => {
  return request.post('/auth/ws_token')
    .then(res => ({ isError: false, value: res.data }))
    .catch(err => {
      apiErrorMsg('WsTokenService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 輸入手機取得國碼
export const GetCountryCodeService = (): ApiService<Auth.GetCountryCode.Response> =>{
  return request.get('/auth/ip_to_country')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCountryCodeService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

