import { toCamel, toUnderline, deepCopy } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './services'
import { parseCompetitionStatus } from '@/utils/helper'

// 取得比賽清單(不需登入)
export const GetCompetitionExploreListService = (): ApiService<Competition.CompetitionExploreList.Response> => {
  return request.get('/competition_explore')
    .then(res => {
      let parsedRes = toCamel(res.data)
      parsedRes.competitions = ((parsedRes.competitions) || []).map((item: any) => {
        if (item.status === 'PUBLISHED') item.status = parseCompetitionStatus(item.timeStart, item.timeEnd)
        return { ...item }
      })

      return parsedRes
    })
    .then(res => ({ isError: false, value: res }))
    .catch(err => {
      apiErrorMsg('GetCompetitionExploreListService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得比賽詳細(不需登入)
export const GetCompetitionExploreDetailService = (query: Competition.CompetitionExploreDetail.Request): ApiService<Competition.CompetitionExploreDetail.Response> => {
  return request.get(`/competition_explore/${ query.competitionId }`)
    .then(res => {
      let parsedRes = toCamel(res.data)
      if(parsedRes.competition.status === 'PUBLISHED') {
        parsedRes.competition.status = parseCompetitionStatus(parsedRes.competition.timeStart, parsedRes.competition.timeEnd)
      }
      return { isError: false, value: parsedRes }
    })
    .catch(err => {
      apiErrorMsg('GetCompetitionExploreDetailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得推廣比賽參加者(不需登入)
export const GetCompetionPromoteUserService = (query: Competition.CompetitionPromoteUser.Request): ApiService<Competition.CompetitionPromoteUser.Response> => {
  return request.get(`/competition_explore/${ query.competitionId }/promote_user`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCompetionPromoteUserService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得交易比賽參加者(不需登入)
export const GetCompetionTradeUserService = (query: Competition.CompetitionTradeUser.Request): ApiService<Competition.CompetitionTradeUser.Response> => {
  return request.get(`/competition_explore/${ query.competitionId }/trade_user`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCompetionTradeUserService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得參加的比賽清單
export const GetCompetitionListService = (query?: Competition.CompetitionList.Request): ApiService<Competition.CompetitionList.Response> => {
  const params = toUnderline(deepCopy(query?.parameters))
  return request.get('/competition', { params })
    .then(res => {
      let parsedRes = toCamel(res.data)
      parsedRes.competitions = ((parsedRes.competitions) || []).map((item: any) => {
        if (item.status === 'PUBLISHED') item.status = parseCompetitionStatus(item.timeStart, item.timeEnd)
        return { ...item }
      })
      return parsedRes
    })
    .then(res => ({ isError: false, value: res }))
    .catch(err => {
      apiErrorMsg('GetCompetitionListService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得比賽詳細(需登入)
export const GetCompetitionDetailService = (query: Competition.Competition.Get.Request): ApiService<Competition.Competition.Get.Response> => {
  return request.get(`/competition/${ query.competitionId }`)
    .then(res => {
      let parsedRes = toCamel(res.data)
      if(parsedRes.competition.status === 'PUBLISHED') {
        parsedRes.competition.status = parseCompetitionStatus(parsedRes.competition.timeStart, parsedRes.competition.timeEnd)
      }
      return { isError: false, value: parsedRes }
    })
    .catch(err => {
      apiErrorMsg('GetCompetitionDetailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 參加比賽
export const JoinCompetitionService = (body: Competition.Competition.Post.Request): ApiService<boolean> => {
  return request.patch(`/competition/${ body.competitionId }`)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('JoinCompetitionService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得比賽錢包清單
export const GetCompetitionWalletListService = (): ApiService<Competition.CompetitionWalletList.Response> => {
  return request.get('/competition_wallet')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCompetitionWalletListService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得比賽錢包
export const GetCompetitionWalletService = (query: Competition.CompetitionWallet.Request): ApiService<Competition.CompetitionWallet.Response> => {
  return request.get(`/competition_wallet/${query.competitionId}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCompetitionWalletService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 資金劃轉回主錢包
export const CompetitionTransferService = (body: Competition.CompetitionTransfer.Request): ApiService<boolean, { code: string }> => {
  return request.patch(`/competition_wallet/${body.competitionId}/transfer`)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('CompetitionTransferService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error}
    })
}

