// 延遲訊息推入
export default function timerQueue () {
  const queue:any[] = []

  let timerID: NodeJS.Timeout

  function push (value: any) {
    queue.push(value)
  }

  function pop () {
    return queue.shift()
  }

  function clearIntervel () {
    clearInterval(timerID)
  }

  function createInteval (callback: Function) {
    timerID = setInterval(() => {
      if (queue.length) callback(queue.pop())
    }, 700)
  }

  return {
    queue,
    push,
    pop,
    createInteval,
    clearIntervel,
  }
}

