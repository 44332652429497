import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetCompetitionListService } from '@/services/api-helper/competition'

const init= {
  firstLoad: true,
  competitions: [] as Common.CompetitionBase[]
}

// extraReducers
const updateCompetition = createAsyncThunk('competition/list', async () => {
  const result = await GetCompetitionListService()
  if(!result.isError) return {
    firstLoad: false,
    competitions: result.value.competitions
  }
  else return {
    firstLoad: false,
    competitions: [] as Common.CompetitionBase[],
  }
})

const competitionSlice = createSlice({
  name: 'competition',
  initialState: init,
  reducers: {
    clearCompetition: () => init
  },

  extraReducers: (builder) => {
    builder.addCase(updateCompetition.fulfilled, (state, action) => action.payload || init)
  },
})

export const competitionsActions = { ...competitionSlice.actions, updateCompetition}
export default competitionSlice.reducer
