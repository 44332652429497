import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetStrategyListService } from '@/services/api-helper/strategy'
import { StrategyOrderby } from '@/utils/enum'

const initStrategy: Common.StrategySymbol[] = []

// extraReducers
const updateStrategy = createAsyncThunk('strategy/list', async (query?: Strategy.Get.Request<StrategyOrderby>) => {
  const result = await GetStrategyListService(query)
  return result.isError === false ? result.value || [] : []
})

const strategySlice = createSlice({
  name: 'strategy',
  initialState: initStrategy,
  reducers: {
    clearStrategy: () => initStrategy,
  },

  extraReducers: (builder) => {
    builder.addCase(updateStrategy.fulfilled, (state, action) => action.payload || [])
  },

})

export const strategyActions = { ...strategySlice.actions, updateStrategy }
export default strategySlice.reducer
