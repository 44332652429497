import { isDev } from '@otsofintech/sofinx-ui/lib/helper'
import { WsTokenService } from '@/services/api-helper/auth'
import store from '@/store'
import { enableWsState, disableWsState } from '@/store/actions'

type Environment = 'test' | 'develop'

// api url
const proxyRouteMap = {
  test: 'wss://ws-broker.sofinx-test.otso-dev.com',
  develop: 'wss://ws-broker.sofinx.otso-dev.com',
}
const proxyRoute = proxyRouteMap[process.env.REACT_APP_MODE as Environment] || 'proxy-error'
const productionHost = document.getElementsByName('ws-host')[0]?.getAttribute('content') || ''
const productionRoute = `wss://${productionHost}`
const WS_URL = isDev() ? proxyRoute : productionRoute

interface WebSocketInter {
  instance: globalThis.WebSocket | null

  isAuth: boolean

  isLoading: boolean

  channelList: string[]

  authChannelList: string[]

  callbackMap: {
    [channel: string]: Map<symbol, Function>
  }

  create: () => void

  reset: () => void

  getAuth: () => void

  clearAuth: () => void

  onSubscribe: (
    channel: string,
    channelString: string,
    callback: Function,
    needAuth: boolean,
    symbol: symbol
  ) => void

  execChannel: (channelKey: 'channelList' | 'authChannelList') => void

  delCallback: (channel: string, symbol: symbol) => void

  send: (channelString: string) => void
}

export const webSocket: WebSocketInter = {
  instance: null,

  isAuth: false,

  isLoading: false,

  channelList: [],

  authChannelList: [],

  callbackMap: {},


  create: async function () {
    this.instance = new WebSocket(WS_URL)

    this.instance.onopen = () => {
      store.dispatch(enableWsState())
      this.execChannel('channelList')
    }

    this.instance.onmessage = (event) => {
      const data = JSON.parse(event.data) as any

      if (data.id === 'auth') {
        this.isAuth = data.error.code === 0
        this.execChannel('authChannelList')
        this.isLoading = false
        return
      }

      if (data.id === 'unauth') {
        this.isAuth = data.error.code === 0
        this.isAuth = false
        return
      }

      if (typeof this.callbackMap[data.channel] === 'object') {
        this.callbackMap[data.channel].forEach((val, key) => val(data))
      }
    }

    this.instance.onclose = (e) => {
      this.reset()
      setTimeout(() => this.create(), 12000)
    }
  },

  reset: function () {
    store.dispatch(disableWsState())
    this.instance = null
    this.isAuth = false
    this.isLoading = false
    this.channelList = []
    this.authChannelList = []
    this.callbackMap = {}
  },

  // 認證
  getAuth: async function () {
    if (this.instance === null) return // show 連線錯誤

    this.isLoading = true

    const result = await WsTokenService()
    if (result.isError) this.isLoading = false
    else this.send(`auth#Auth@${result.value.token}`)
  },

  clearAuth: function () {
    this.send('unauth#Unauth')
  },

  // 訂閱or退訂
  onSubscribe: function (channel, channelString, callback, needAuth, symbol) {
    // 已認證 直接送出
    if (this.isAuth) this.send(channelString)

    else if (needAuth === false) this.send(channelString)
    else {
      this.authChannelList.push(channelString)
      if (this.isLoading === false) this.getAuth()
    }

    // 加入callback
    if (this.callbackMap[channel]) this.callbackMap[channel].set(symbol, callback)
    else this.callbackMap[channel] = new Map([[symbol, callback]])
  },

  execChannel: function (channelKey: 'channelList' | 'authChannelList') {
    this[channelKey].forEach(channel => this.send(channel))
    this[channelKey] = []
  },

  delCallback: function (channel, symbol) {
    if(this.callbackMap[channel]) this.callbackMap[channel].delete(symbol)
  },

  send: function (channelString: string) {
    if (this.instance?.readyState !== 1) this.channelList.push(channelString)
    else this.instance?.send(channelString)
  }
}
