import { toCamel } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './services'

// 取得可交易品種清單
export const GetConfigSymolsService = (): ApiService<Common.ConfigSymbol[]> => {
  return request.get('/config/symbol')
    .then(res => ({ isError: false, value: toCamel(res.data.symbols) }))
    .catch(err => {
      apiErrorMsg('GetConfigSymolsService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 公司資料
export const GetCompanyInfoService = (): ApiService<Config.Company.Res> => {
  return request.get('/config/company')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCompanyInfoService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 公司客製化連結
export const GetCompanyCustomizedMenuService = (): ApiService<Config.CompanyCustomizedMenu.Res> => {
  return request.get('/config/customized_menu')
    .then(res => ({ isError: false, value: toCamel(res.data.items) }))
    .catch(err => {
      apiErrorMsg('GetCompanyCustomizedMenuService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}