import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { SFSVG } from '@otsofintech/sofinx-ui'

export const Header = styled.header<{isLogin: boolean }>`
  @media screen and (max-width: ${ props => props.theme.breakpoint.tablet - 1 }px) { padding: 0 16px };
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 24px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: ${ props => props.theme.palette.blank };
  border-bottom: 1px solid ${ props => props.theme.palette.grey20 };
  z-index: 10;
`

export const NavMenu = styled.div`

  margin-left: 24px;
  display: flex;
  align-items: center;
`

export const NavItem = styled(Link)`
  margin-right: 24px;
  font-size: 14px;
  font-weight: 500;
  color: ${ props => props.theme.palette.grey50 };
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const LinkItem = styled.a`
  margin-right: 24px;
  font-size: 14px;
  font-weight: 500;
  color: ${ props => props.theme.palette.grey50 };
  cursor: pointer;
  display: flex;
  align-items: center;
` 

export const Panel = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`
export const LoginLink = styled(Link)`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${ props => props.theme.palette.grey50 };
  :hover {
    color: ${ props => props.theme.palette.primary };
  }
`

export const Badge = styled.div<{ $haveMsg: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  &:before {
    ${ props => props.$haveMsg ? 'content: ""' : 'none' };;
    position: absolute;
    top: 0;
    right: 0px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 2px solid ${ props => props.theme.palette.white };
    background-color: ${ props => props.theme.palette.primary };
  }
`

export const MessageBox = styled(SFSVG)<{ $haveMsg: number }>`
  fill: ${ props => props.theme.palette.grey50 };
  width: 24px;

  &:hover {
    fill: ${ props => props.theme.palette.primary };
  } 
`
export const Split = styled.div`
@media screen and (max-width: ${ props => props.theme.breakpoint.tablet - 1}px) { margin: 0 8px; };
  margin: 0 12px;
  width: 1px;
  height: 12px;
  background-color: ${ props => props.theme.palette.grey100 };
`