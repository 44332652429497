import styled, { keyframes } from 'styled-components'
import Close from '@material-ui/icons/Close'

export const  NotifyWrapper = styled.div`
  position: relative;
  height: 0;
  transition: all .5s cubic-bezier(0.15, 0.91, 0.47, 0.96);

  &.slide {
    transform: translateX(calc(-100% - 24px));
  }
  &.expend {
    height: 98px;
  }
`

const slide = keyframes`
  0% { transform: translateX(calc(100%)) }
  100% { transform: translateX(0) }
`

export const NotifyBar = styled.div<{ reverseIndex: number }>`
  position: absolute;
  top: 0;
  margin-bottom: 16px;
  width: 336px;
  height: 82px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${ props => props.theme.palette.blank };
  box-shadow: ${ props => props.theme.shadow[700] };
  transform: translateX(calc(0));
  transition: transform .5s cubic-bezier(0.15, 0.91, 0.47, 0.96);
  animation: ${slide} .5s cubic-bezier(0.15, 0.91, 0.47, 0.96);
  cursor: pointer;

  &.covered {
    transform:
      scaleX(${ props => 1 - (props.reverseIndex) * 0.05 })
      translateY(${ props => props.reverseIndex * 10 }px);
  }
`

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  margin-top: 8px;
`

export const CloseIcon = styled(Close)`
  margin-left: auto;
  color: ${ props => props.theme.palette.grey30 };
  cursor: pointer;
`
