import styled, { css } from 'styled-components'


const hoverCss = css`
  height: 80px;
  background-color: #191919;

  .arrow-icon {
    opacity: 1;
    fill: ${ props => props.theme.palette.grey40 };
  }

  .visibility-icon {
    fill: ${ props => props.theme.palette.grey40 };
  }

  .amount {
    top: 0px;
  }

  .amount-title {
    opacity: 1;
    top: 0;
  }

  .deposit-button {
    opacity: 1;
  }
`

export const Main = styled.section<{ hover: 1 | 0, hideForSofinq: boolean }>`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
  background-color: #191919CC;
  display: flex;
  backdrop-filter: blur(20px);
  align-items: center;
  margin: auto;
  transition: all ${ props => props.theme.curve.default } .2s;
  z-index: 10;
  visibility: ${props=>props.hideForSofinq ? 'hidden' : 'visible'};

  .arrow-icon {
    transform: rotate(180deg);
    opacity: 0;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .visibility-icon {
    cursor: pointer;
    fill: ${ props => props.theme.palette.grey50 };
    user-select: none;
    margin-left: 16px;
    margin-right: 24px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .amount {
    transition: all ${ props => props.theme.curve.default } .2s;
    position: relative;
    top: 12px;
    font-weight: 600;
    span {
      width: -webkit-fill-available;
      display: block;
    }
  }

  .amount-title {
    transition: all ${ props => props.theme.curve.default } .2s;
    opacity: 0;
    position: relative;
    top: 12px;
  }

  .deposit-button {
    transition: all ${ props => props.theme.curve.default } .2s;
    opacity: 0;
    width: 136px;
    margin-right: 32px;
  }

  .reactive {
    display: flex;
    align-items: center;
  }

  .wallet-label {
    width: fit-content;
    max-width: 100%;
  }

  @media screen and (max-width: 1080px) {
    .reactive {
      display: none;
    }

    .wallet-label-container {
      width: 24px;
    }

    .wallet-label {
      display: none;
    }
  }

  /* 錢包選單開啟狀態套用hoverCss */
  ${ props => props.hover ? hoverCss : null }
`

export const WalletContainer = styled.div`
  width: 304px;
  padding-left: 32px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AmountContainer = styled.div`
  width: 856px;
  display: flex;
  align-items: center;
`

export const AmountColumn = styled.div`
  width: 120px;
  flex-shrink: 0;
`

export const ActionContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const WalletMenuContainer = styled.div<{ open: 1 | 0 }>`
  position: fixed;
  width: fit-content;
  margin: auto;
  height: ${ props => props.open ? 'auto' : 0 };
  visibility: ${ props => props.open ? 'visible' : 'hidden' };
  bottom: 88px;
  left: 8px;
  z-index: 10;
`

export const WalletMenu = styled.div`
  height: 100px;
  width: fit-content;
  background-color: #191919;
  border-radius: 8px;
  padding: 8px 0;
`

export const WalletMenuItem = styled.div<{ active: 1 | 0, $darkMode: boolean }>`
  color: ${ props => {
    if(props.$darkMode) return props.active ? props.theme.palette.grey40 : props.theme.palette.blank
    else return props.active ? props.theme.palette.blank : props.theme.palette.grey40
  }
};

  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all ${ props => props.theme.curve.default } .2s;
  user-select: none;
  padding: 0 24px;

  &:hover {
    color: ${ props => props.$darkMode ? props.theme.palette.grey40 : props.theme.palette.blank };
  }
`

export const WalletName = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
`

export const Tutorial = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
`

export const TutorialDialog = styled.div`
  position: relative;
  width: 400px;
  height: fit-content;
  margin-top: auto;
  margin-bottom: 88px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
  border-radius: 16px;
  background-color: ${ props => props.theme.palette.blank };
  
  & .dialog_title {
    color:  ${ props => props.theme.palette.grey60 };
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-bottom: 8px;
  }

  & .dialog_desc {
    color:  ${ props => props.theme.palette.grey40 };
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    margin-bottom: 24px;
  }

  & .dialog_action {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`