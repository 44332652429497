import { toCamel, toUnderline, deepCopy } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './services'

// 檢查username是否存在
export const GetCheckUsernameService = (query:User.CheckUsername.Request): ApiService<User.CheckUsername.Response> => {
  const params = toUnderline(deepCopy(query))

  return request.get('/user_check/username', { params })
    .then(res => ({ isError: false, value: res.data.exists }))
    .catch(err => {
      apiErrorMsg('GetCheckUsernameService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 檢查email是否存在
export const GetCheckEmailService = (query:User.CheckEmail.Request): ApiService<User.CheckEmail.Response> => {
  const params = toUnderline(deepCopy(query))

  return request.get('/user_check/email', { params })
    .then(res => ({ isError: false, value: res.data.exists }))
    .catch(err => {
      apiErrorMsg('GetCheckEmailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 檢查phone是否存在
export const GetCheckPhoneService = (query:User.CheckPhone.Request): ApiService<User.CheckPhone.Response> => {
  const params = toUnderline(deepCopy(query))

  return request.get('/user_check/phone', { params })
    .then(res => ({ isError: false, value: res.data.exists }))
    .catch(err => {
      apiErrorMsg('GetCheckPhoneService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 依email 檢查username是否為空
export const GetUsernameIsEmptyByEmailService = (query: User.CheckUsernameByEmail.Req): ApiService<User.CheckUsernameByEmail.Res> => {
  const params = toUnderline(deepCopy(query))
  return request.get('/username_check/email', { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetUsernameIsEmptyByEmailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 依phone 檢查username是否為空
export const GetUsernameIsEmptyByPhoneService = (query: User.CheckUsernameByPhone.Req): ApiService<User.CheckUsernameByPhone.Res> => {
  const params = toUnderline(deepCopy(query))
  return request.get('/username_check/phone', { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetUsernameIsEmptyByPhoneService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得個人資訊
export const GetUserService = (): ApiService<Common.User> => {
  return request.get('/user')
    .then(res => ({ isError: false, value: toCamel(res.data.user) }))
    .catch(err => {
      return { isError: true, value: err.response?.data?.error }
    })
}

// 修改使用者語系
export const PutUserLangService = (query: User.ChangeLang.Resquest): ApiService<boolean> => {
  const params = toUnderline(deepCopy(query))

  return request.put('/user/language', params)
    .then(res => ({ isError: false, value: toCamel(res.data.user) }))
    .catch(err => {
      apiErrorMsg('GetUserService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 修改使用者大頭貼
export const PatchUserHeadshotService = (query: User.ChangeHeadshot.Resquest): ApiService<boolean> => {
  const formData = new FormData()
  formData.append('change_headshot', String(query.changeHeadshot))
  formData.append('headshot_file', query.headshotFile)

  return request.patch('/user/headshot', formData)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('PatchUserHeadshotService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得使用者推薦資訊
export const GetReferralInfo = (): ApiService<User.UserReferral.Response> => {
  return request.get('/user/referral')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetReferralInfo', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得使用者設定
export const GetUserSettingService = (): ApiService<User.SettingInfo.Response> => {
  return request.get('/user/setting')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetUserSettingService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得登入中裝置
export const GetLoginDeviceService = (): ApiService<User.LoginDevice.Response> => {
  return request.get('/user/device')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetLoginDeviceService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 移除登入中裝置
export const DelLoginDeviceService = (token: string): ApiService<boolean> => {
  return request.delete(`/user/device/${token}`)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('DelLoginDeviceService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得登錄紀錄
export const GetLoginHistoryService = (query: User.LoginHistory.Request): ApiService<User.LoginHistory.Response> => {
  const params = deepCopy(toUnderline(query))
  return request.get('/user/device/record', { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetLoginHistoryService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
