import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetCompetitionWalletListService } from '@/services/api-helper/competition'

const initWallets: Common.CompetitionWallet[] = []

// extraReducers
const updateCompetitionWallet = createAsyncThunk('competition/wallet', async () => {
  const result = await GetCompetitionWalletListService()
  return result.isError === false ? result.value?.wallets : initWallets
})

const walletSlice = createSlice({
  name: 'wallet',
  initialState: initWallets,
  reducers: {
    clearCompetitionWallet: () => initWallets,
  },

  extraReducers: (builder) => {
    builder.addCase(updateCompetitionWallet.fulfilled, (state, action) => {
      if (action.payload) return action.payload
    })
  },
})

export const competitionWalletActions = { ...walletSlice.actions, updateCompetitionWallet}
export default walletSlice.reducer
