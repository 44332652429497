import { createSlice } from '@reduxjs/toolkit'

const tutorialStepSlice = createSlice({
  name: 'tutorialStep',
  initialState: 0,
  reducers: {
    setTutorialStep: (state, action: { payload: number }) => {
      return action.payload
    },
    clearTutorialStep: () => {
      return 0
    }
  }
})

export const tutorialStepActions = tutorialStepSlice.actions
export default tutorialStepSlice.reducer