import { configureStore, combineReducers } from '@reduxjs/toolkit'
import userInfoReducer from './slices/userInfoReducer'
import authReducer from './slices/authReducer'
import symbolsReducer from './slices/symbolsReducer'
import strategyReducer from './slices/strategyReducer'
import walletReducer from './slices/walletReducer'
import robotReducer from './slices/robotReducer'
import competitionReducer from './slices/competitionReducer'
import settingReducer from './slices/settingReducer'
import themeReducer from './slices/themeReducer'
import companyInfoReducer from './slices/companyInfoReducer'
import competitionWalletReducer from './slices/competitionWalletReducer'
import wsStateReducer from './slices/wsStateReducer'
import favoriteSignalIdsReducer from './slices/favoriteSignalIdsReducer'
import creditDepositReducer from './slices/creditDepositReducer'
import tutorialStepReducer from './slices/tutorialStepReducer'
import brokerSubscriptionReducer from './slices/brokerSubscriptionsReducer'
import companyCustomizedMenuReducer from './slices/companyCustomizedMenuReducer'


const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  auth: authReducer,
  symbols: symbolsReducer,
  strategy: strategyReducer,
  wallet: walletReducer,
  robot: robotReducer,
  competition: competitionReducer,
  setting: settingReducer,
  theme: themeReducer,
  companyInfo: companyInfoReducer,
  companyCustomizedMenu: companyCustomizedMenuReducer,
  competitionWallet: competitionWalletReducer,
  wsState: wsStateReducer,
  favoriteSignalIds: favoriteSignalIdsReducer,
  creditDeposit: creditDepositReducer,
  tutorialStep: tutorialStepReducer,
  brokerSubscriptions: brokerSubscriptionReducer
})

const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>

export default store
