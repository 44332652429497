import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetUserWalletService } from '@/services/api-helper/assets'
import { UserWallet } from '@/utils/class'

const initWallet = UserWallet()

// extraReducers
const updateUserWallet = createAsyncThunk('user/wallet', async () => {
  const result = await GetUserWalletService()
  return result.isError === false ? result.value : initWallet
})

const walletSlice = createSlice({
  name: 'wallet',
  initialState: initWallet,
  reducers: {
    clearWallet: () => initWallet,
  },

  extraReducers: (builder) => {
    builder.addCase(updateUserWallet.fulfilled, (state, action) => {
      if (action.payload) return action.payload
    })
  },
})

export const walletActions = { ...walletSlice.actions, updateUserWallet}
export default walletSlice.reducer
