import { deepCopy, toCamel, toUnderline } from '@otsofintech/sofinx-ui/lib/helper'
import { ApiService, apiErrorMsg, request } from './services'

export const GetBrokerSubscriptions = (): ApiService<Subscriptions.GetBrokerSubscriptions.Res> => {
  return request.get('/subscriptions').then(res => ({ isError: false, value: toCamel(res.data.subscriptions) })).catch(err => {apiErrorMsg('GetBrokerSubscriptions', err.response?.data?.error)
    return { isError: true, value: err.response?.data?.error }
  })
}

export const GetUserIdentityVerification = (): ApiService<Subscriptions.GetUserIdentityVerification.Res> => {
  return request.get('/subscriptions/identity_verification/status').then(res => ({isError: false, value: toCamel(res.data)})).catch(err => {
    apiErrorMsg('GetUserIdentityVerification', err.response?.data?.error)
    return {isError: true, value: err.response?.data?.error}
  })
}

export const GetIdentityVerificationSetting = (): ApiService<Subscriptions.GetIdentityVerificationSetting.Res> => {
  return request.get('/subscriptions/identity_verification/setting').then(res => ({isError: false, value: toCamel(res.data)})).catch(err => {
    apiErrorMsg('GetIdentityVerificationSetting', err.response?.data?.error)
    return {isError: true, value: err.response?.data?.error}
  })
}

export const GetIdentityVerificationUserData = (): ApiService<Subscriptions.GetIdentityVerificationUserData.Res> => {
  return request.get('/subscriptions/identity_verification').then(res => ({isError: false, value: toCamel(res.data)})).catch(err => {
    apiErrorMsg('GetIdentityVerificationUserData', err.response?.data?.error)
    return {isError: true, value: err.response?.data?.error}
  })
}

export const PostIdentityVerificationUserData = (query: Subscriptions.PostIdentityVerificationUserData.Req): ApiService<any> => {
  const params = deepCopy(toUnderline(query)) as any
  const formData = new FormData()
  Object.keys(params).forEach((key) =>{
    if(key !== 'id_card_front_image' && key !== 'id_card_back_image' && key !== 'residency_proof_image' && key !== 'selfie_with_id_card_image')
      formData.append(key, params[key])}
  )

  if(query.idCardFrontImage) formData.append('id_card_front_image', query.idCardFrontImage.localUpload? query.idCardFrontImage.localUpload : query.idCardFrontImage.remoteUrl)
  if(query.idCardBackImage) formData.append('id_card_back_image', query.idCardBackImage.localUpload? query.idCardBackImage.localUpload : query.idCardBackImage.remoteUrl)
  if(query.selfieWithIdCardImage) formData.append('selfie_with_id_card_image', query.selfieWithIdCardImage.localUpload? query.selfieWithIdCardImage.localUpload : query.selfieWithIdCardImage.remoteUrl)
  if(query.residencyProofImage) formData.append('residency_proof_image', query.residencyProofImage.localUpload ? query.residencyProofImage.localUpload : query.residencyProofImage.remoteUrl)
  return request.post('/subscriptions/identity_verification', formData).then(res => ({isError: false, value: toCamel(res.data)})).catch(err => {
    apiErrorMsg('PostIdentityVerificationUserData', err.response?.data?.error)
    return {isError: true, value: err.response?.data?.error}
  })
}
