import { useEffect, useRef } from 'react'
import useMessage, { iconMap, IconTypes } from '@/hooks/useMessage'
import { SFText, SFSVG } from '@otsofintech/sofinx-ui'
import { MessageBar, Content, CloseIcon } from './style'

const Message = () => {
  const { messager } = useMessage()
  const messageNode = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (messageNode.current === null) return
    if (messager.message === '') return

    messageNode.current.classList.remove('slide-in')
    messageNode.current.classList.remove('appear')

    window.requestAnimationFrame(() => {
      if (messageNode.current === null) return
      messageNode.current.classList.add('slide-in')
      messageNode.current.classList.add('appear')
    })
  }, [messager])

  const handleClose = () => {
    if (messageNode.current === null) return
    messageNode.current.classList.remove('appear')
    setTimeout(() => messageNode.current!.classList.remove('slide-in'), 200)
  }

  return (
    <MessageBar ref={messageNode}>
      {
        iconMap[messager.icon as IconTypes] ?
          <SFSVG
            src={iconMap[messager.icon as IconTypes].icon}
            color={iconMap[messager.icon as IconTypes].color}
          />  : messager.icon
      }
      <Content>
        <SFText width="204px" level={2} color="grey50">{ messager.message }</SFText>
      </Content>
      <CloseIcon onClick={handleClose} />
    </MessageBar>
  )
}

export default Message
