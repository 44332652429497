import { Column } from '@otsofintech/sofinx-ui/lib/components/SFTable'
import dayjs from 'dayjs'
import FileSaver from 'file-saver'
import { TimePeriod } from './enum'

export const parseCompetitionStatus = (timeStart: string, timeEnd: string): Common.CompetitionStatus => {
  const currentTime = new Date().getTime()
  const startTime = dayjs(timeStart).valueOf()
  const endTime = dayjs(timeEnd).valueOf()

  if(currentTime < startTime) return 'COMING'
  else if (currentTime >= startTime && currentTime < endTime) return 'STARTED'
  else return 'ENDED'
}

export const isOTSOProduction = (): boolean => {
  const paperTrade = 'broker.sofinx.com'
  return document.domain === paperTrade
}

/// 下載tabel資料
export const downloadTableCSV = (columns: Column<any>[], dataForDownload: any[], filename: string = 'file.csv') => {
  let csvStr = ['']

  /// 標題列
  columns.forEach(item => {
    csvStr[0] += item.label + ','
  })

  /// 資料列
  dataForDownload?.forEach((item: any) => {
    let rowData = '\n'
    for (let col of columns) {
      rowData += (item[col.id] === undefined ? '' : item[col.id]) + ','
    }
    csvStr[0] += rowData
  })

  let blob = new Blob(['\uFEFF' + csvStr], { type: 'text/csv' })

  FileSaver.saveAs(blob, filename)
}

// 將任意資料筆數取樣至固定資料筆數
export const profitDatasSamplingToFixedNumberOfData = (profitDatas: Common.ProfitData[], numberOfData: number) => {
  if(profitDatas.length === 0|| numberOfData === 0) return []

  if(numberOfData === 1 || profitDatas.length === 1) return [profitDatas[0]]

  if(profitDatas.length <= numberOfData) return profitDatas

  const resultDatas = []

  /// 第一個點
  resultDatas.push(profitDatas[0])

  if(profitDatas.length > 2) {

    const unit = (profitDatas.length - 1) / (numberOfData - 1)

    for(let i = 1; i < numberOfData - 1; i++ ) {
      const index = Math.floor(unit * i)
      resultDatas[i] = profitDatas[index]
    }
  }

  /// 最後一個點
  resultDatas.push(profitDatas[profitDatas.length - 1])

  return resultDatas
}


/**
 * profit圖表 取固定筆數收益資料
 * 7天: last 7
 * 30天: last 30
 * 90天: last 90 取 10
 * 180天: last 180 取 15
 * 所有: all 取 10
 */
export const getPorfitChartData = (profitData: any[], period: TimePeriod) => {
  const data = [...profitData]
  let list = [] as Common.ProfitData[]


  const now = dayjs()

  // 是否在指定天數內
  const inDuration = (time: string, days: number): boolean => dayjs(time).isValid() && dayjs.duration(now.diff(dayjs(time))).asDays() < days

  if(period === TimePeriod['7d']) list = data.filter(item => inDuration(item.time, 7))

  else if(period === TimePeriod['30d']) list = data.filter(item => inDuration(item.time, 30))

  else if(period === TimePeriod['90d']) {
    let temp = data.filter(item => inDuration(item.time, 90))

    list = profitDatasSamplingToFixedNumberOfData(temp, 10)
  }

  else if(period === TimePeriod['180d']) {
    let temp = data.filter(item => inDuration(item.time, 180))

    list = profitDatasSamplingToFixedNumberOfData(temp, 15)
  }

  else {
    let temp = data

    list = profitDatasSamplingToFixedNumberOfData(temp, 10)
  }

  return list
}


/**
 * 比率類型數值顯示
 *
 * 2位數+小數點後兩位
 * 3位數以上不顯示小數點
 */
export const ellipsisRatioValue = (value: string | number) => {
  const number = Number(value)

  if(isNaN(number)) return value


  if(number >= 100 || number < -100) return number.toFixed(0)
  else return number.toFixed(2)
}


export const getQueryString = (query: object) => {
  let str = ''
  let isFirst = true

  Object.keys(query).forEach(key => {
    if(query[key as keyof typeof query] !== '' && query[key as keyof typeof query] !== undefined) {
      
      if(Array.isArray(query[key as keyof typeof query])) {
        let arr = [...query[key as keyof typeof query]]

        if(arr.length) {
          let strArr = arr.map(item => `${ key }=${ item }`).filter( item => item !== '')
          
          str += `${ isFirst ? '' : '&'}${ strArr.join('&') }`
        }    
      }

      else str += `${ isFirst ? '' : '&'}${ key }=${ query[key as keyof typeof query] }`

      isFirst = false
    }
  })

  return str
}

//辨認當前domain是否為需要對其隱藏部分功能的domain，目前指定需對sofinq站點隱藏，為了開發方便所以也對localhost隱藏
const sofinqDomain=['temp.sofinq.otso-dev.com','temp.sofinq-test.otso-dev.com','sofinq.com','q.sofinq.otso-dev.com','q.sofinq-test.otso-dev.com','q.sofinq.com']
export const isSofinqDomain = () => {
  const domain = window.location.hostname
  return sofinqDomain.includes(domain)
}